import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Modall from '../Modall'
import InputUniversal from '../ui/InputUniversal'
const GET_JOBS_URL = 'admin/setting/job'
const ModalNewRoles = ({showModal, children, handleColse, getDepartment, row = ""}) => {
    const [nameRole, setnameRole] = useState("")
    const [error, setError] = useState("")
    useEffect(() => {
        if (row) {
            setnameRole(row.description)
        }
    }, [])

    const handleEditRole = async (id) => {

        await axios.put(`${GET_JOBS_URL}/${id}`, JSON.stringify({ 'des': nameRole }), {
            headers: { 'Content-Type': 'application/json' }
        }).then(response => {
            if (response.status == 200) {
                getDepartment()
                handleColse()
                console.log("Edit")
            }
        })
    }
    const addNameRole = async () => {
        const response = await axios.post(GET_JOBS_URL, JSON.stringify({ 'des': nameRole }), {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log(response)
        if (response?.status == 201) {
            getDepartment()
            handleColse()
        }
        else {
            console.log("error add class")
        }
    }


    const handleCheck = (name) => {
        if (!name) {
            setError("תיאור התפקיד ריק")
        }
        else {
            setError("")
        }
        setnameRole(name)

    }



    return (
        <Modall showModal={showModal}>
            <div className=' w-full divide-y-2  divide-slate-500'>
                <div className='w-full bg-[#0d406b] flex justify-end'>
                    <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={() => {
                       handleColse()
                    }} >x</button>
                </div>
                {/* <div className='w-full p-5 flex flex-col justify-end '> */}
                    {/* {children} */}
                    {/* <div className='w-full  pb-0 p-3 flex justify-end '>

                        <input type="text" className='border w-[70%] text-right' value={nameRole} onChange={(e) => { handleCheck(e.target.value) }} />
                        <label className='flex px-4'><p className='text-red-600'>*</p>תיאור התפקיד</label>

                    </div>
                    {error && <p className='text-red-500 text-center'>{error}</p>}

                </div> */}

                <div className='w-full p-5 flex flex-col justify-end ' style={{direction:"rtl"}}>
                <InputUniversal label="תיאור התפקיד" id="nameRole"  
                            req={true}   
                            className='grid col-span-5  border'
                                type="text"
                                
                                name="class"
                                error={error}
                                value={nameRole}
                                // value={formData.lastName}
                                onChange={handleCheck}
                            />
                 </div>      

                <div className='w-full p-2   flex justify-end'>
                    <button className='border bg-green-500 hover:bg-green-300 p-1 px-3' onClick={() => {
                        if (nameRole != "") {
                            row ? handleEditRole(row.id) : addNameRole()
                        }
                        else
                        (setError("תיאור התפקיד ריק"))


                    }}>שמור</button>
                    <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3' onClick={() => {
                        // reset()
                        // setShowModal(false)
                        handleColse()
                    }}>בטל</button>
                </div>
            </div>

        </Modall>
    )
}

export default ModalNewRoles