import React, { useState } from 'react'

import Select from "react-select"
import Fillter from '../Fillter'

const Paying_customersFilter = ({setDataFillterout,datafiler }) => {
    
    // const GET_Filter=`admin/qualification/get_register_member_qualification/${row?.id}?sortBy=name`

    const [dataFillter, setDataFillter] = useState({
        'name': '',
        'identity': ''
    })

    const restart = () => {
        setDataFillter(
            {
                'name':'',
                'identity': ''
            }

        )
        setDataFillterout({
            'name':'',
            'identity': ''
        })
    }


   
    const handleChange = (selectedOption, name,) => {
        // console.log(selectedOption)
        // const { name } = e.name;
        setDataFillter({
            ...dataFillter,
            [name]: selectedOption?.value
            ,
        })



    }  
    // const [datafiler, setDatafiler] = useState("");
  
    const actionFilter = () => {
        setDataFillterout(dataFillter)
    }

    
    


    return (
        <Fillter actionFilter={actionFilter} restart={restart}>
            <div className='grid grid-cols-2 '>

                <div className='flex justify-end items-center'>
                    <Select className=' w-2/3 text-right p-2' placeholder='חפש'
                        type="text"
                        id="identity"
                        name="identity"
                        value={dataFillter?.identity?{ value: dataFillter.identity, label: dataFillter.identity }:null}
                        options={datafiler?.identity?.map((item) => { return { value: item, label: item } })}
                        isClearable
                        isSearchable
                        maxMenuHeight={100} 
                        onChange={(e) => handleChange(e, 'identity')} />
                        

                    <label className='ml-3' htmlFor="identity">ח.פ. / ע.מ. / ת.ז.</label>
                </div>
                <div className=' flex justify-end items-center '  >
                    <Select className=' w-2/3 text-right p-2 ' placeholder='חפש'
                        type="text"
                        id="name"
                        name="name"
                        value={dataFillter?.name?{ value: dataFillter.name, label: dataFillter.name }:null}
                        options={datafiler?.names?.map((item) => { return { value: item, label: item } })}
                        isClearable
                        isSearchable
                        onChange={(e) => handleChange(e, 'name')} />
                   
                    <label className='ml-3 ' htmlFor="name">שם לקוח משלם</label>

                </div>
            </div>
        </Fillter>
    )
}

export default Paying_customersFilter