import React from 'react'

const Input = ({ label, id, error ,className,req=false, ...props }) =>  {
    return (
      <div className="w-full">
        <div className={error?'text-red-500  grid grid-cols-10 my-1':'grid grid-cols-10 my-1'}>
        {/* <label className='grid col-span-3 ' htmlFor={id} >  {label} {req?<span className='text-red-600'>*</span>:""} </label> */}
            <label className="col-span-3 " htmlFor={id}>
              <span className="flex justify-center">
                  {label}
                  {req && <span className="text-red-600 ml-1">*</span>}
              </span>
            </label>

             <input  id={id} className={error?` border-red-500 ${className}`:className} {...props} />
        </div>
        < div className="text-red-500 text-center">{error && <p>{error}</p>}</div>
      </div>)
}

export default Input



// <div className="w-full">
//         <div className="w-full pt-2 flex justify-end">

//             <input id={id} {...props} />
//             <label className='w-[30%]' htmlFor={id}>{label}</label>
//             {/* <div className="control-error">{error && <p>{error}</p>}</div> */}
           
//         </div>
//         <div className="text-[#ffca99]
//         text-[0.8rem]
//         height-[2rem];
//         padding-[0.5rem 0]">{error && <p>{error}</p>}</div></div>



// <div className="control no-margin">
//         <label htmlFor={id}>{label}</label>
//         <input id={id} {...props} />
//         <div className="control-error">{error && <p>{error}</p>}</div>
//       </div>