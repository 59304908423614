import React from 'react'
import { useState } from 'react'
import { RiFileExcel2Line } from "react-icons/ri";

const ExcleButtonDownload = ({downloadExcel}) => {
    const [loading,setLoading]=useState(false)
  return (
    <button onClick={async()=>{
        setLoading(true)
        
        await downloadExcel()
        setLoading(false)}} disabled={loading} className={`${loading?"bg-slate-400":"bg-green-500"} text-white w-fit mx-2 p-2 rounded`}>
        {loading ? (
          // אייקון טעינה - ספירלה מסתובבת
          <span className="flex items-center">
            <svg className="animate-spin h-5 w-5 text-white mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
            </svg>
            ... מוריד
          </span>
        ) : (
          // הטקסט של הכפתור במצב הרגיל
          <span className="flex items-center"> Excle <RiFileExcel2Line/></span>
        )}
      </button>
  )
}

export default ExcleButtonDownload