import axios from "axios";
import React, { useEffect, useState } from "react";


const NewAddMember = ({selectedItems ,setSelectedItems}) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [options,setOptions] = useState([]);
    // const [selectedItems, setSelectedItems] = useState([]);
    const [show, setShow] = useState(false);
    
    const GET_URL = `admin/setting/paying_customer/search_all/member`
    // const GET_PAYING_CUSTOMERS_URL = 'admin/setting/paying_customers_page'

    const GetFilter=async()=>{
        // setloading(true)
        await axios.get(GET_URL, {
        //    
        })
            .then(response => {
                if (response.status == 200) {
                    // console.log(response?.data?.payingDto)
                    // console.log(response?.data)
                    setOptions(response?.data)
                }
            })
        // setloading(false)
    }




    const handleSearch = (e) => {
      setSearchTerm(e.target.value);
    };
  
    const handleSelect = (item) => {
      if (!selectedItems.includes(item)) {
        setSelectedItems([...selectedItems, item]);
      }
      setSearchTerm("");
      setShow(false)// איפוס החיפוש
    };
  
    const handleRemove = (item) => {
      setSelectedItems(selectedItems.filter((i) => i !== item));
    };
  
    const filteredOptions = 
    options?.filter((option) =>
      option?.display_member?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    // console.log(filteredOptions)
  
    console.log(selectedItems) 
    useEffect(()=>{

        GetFilter()
    },[])
    return (
      <div  className="relative" style={{ width: "100%", margin: "0 auto", textAlign: "end" }}>
        <h3>חיפוש והוספה</h3>
        <input
         className="text-right border border-spacing-10"
          type="text"
          placeholder="...חפש כאן"
          value={searchTerm}
          onChange={handleSearch}
          onFocus={()=>setShow(true)}
        //   on={()=>setShow(false)}
          style={{ width: "100%", padding: "10px",
            //  marginBottom: "10px"
             }}
        />
        {show&&<div 
        // onBlur={()=>setShow(false)}
        className="absolute "
        style={{ border: "1px solid #ccc",  width:"100%", maxHeight: "150px", overflowY: "auto",zIndex:"10 ", background:"white" }}>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
                !selectedItems.includes(option)?
              <div
                key={option?.id}
                style={{
                  padding: "10px",
                  cursor: "pointer",
                  backgroundColor: selectedItems.includes(option) ? "#e0e0e0" : "#fff",
                }}
                onClick={() => handleSelect(option)}
              >
                {option?.display_member}
              </div>
              :null
            ))
          ) : (
            <div style={{ padding: "10px", color: "#999" }}>לא נמצאו תוצאות</div>
          )}
        </div>}
        {selectedItems.length>0&&<h4>רשימה נבחרת:</h4>}
        <ul className=" flex flex-col items-center  "> 
          {selectedItems?.map((item) => (
            <li key={item?.id} className="w-3/4 flex flex-row-reverse items-center border">
              <div className="w-1/3">{item?.firstName}</div>
              <div className="w-1/3">{item?.lastName}</div>
              <div className="w-1/3">{item?.number}</div>
              {/* {item?.lastName}
              {item?.number} */}
              <button
                onClick={() => handleRemove(item)}
                style={{
                    padding:"5px",
                  marginLeft: "10px",
                  background: "red",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                הסר
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  };


export default NewAddMember



