import React, { useState } from "react";
import { FaHome, FaEdit, FaShoppingBasket } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const NavBar = () => {





    const handleSelect = (item) => {
        setSelected(item);
    };
 const [selected, setSelected] = useState("home");
    return (
        <div className=" flex justify-between items-center bg-black text-white p-4 px-6 rounded-full w-full max-w-lg mx-auto shadow-lg fixed left-0 bottom-0">
            {/* <div className="flex gap-10"> */}
            {/* <div
                onClick={() => handleSelect("edit")}
                className={`flex flex-col items-center text-gray-400 hover:text-blue-500 cursor-pointer transition-all duration-300 ${selected === "edit" ? "-translate-y-2 text-blue-500" : ""
                    }`}
            >
                <FaEdit size={20} />
                <span className="text-sm">חתימות</span>
            </div> */}

            <NavLink to="Signatures" 

                onClick={() => handleSelect("edit")}
                className={`flex flex-col items-center text-gray-400 hover:text-blue-500 cursor-pointer "
}`}

            >

                {selected === "edit" ?
                    <div className="absolute top-[-20px]  bg-blue-500 w-12 h-12 flex items-center justify-center rounded-full shadow-md ring-8 ring-white">
                        <FaEdit size={20} className="text-white" />
                    </div> : ""}
                <FaEdit size={20} className={`${selected === "edit" ? "disabled" : ""} `} />
                <span className="text-sm">חתימות</span>
            </NavLink>



            <NavLink to="MyCourses" 

                onClick={() => handleSelect("basket")}
                className={`flex flex-col items-center text-gray-400 hover:text-blue-500 cursor-pointer "
                }`}

            >

                {selected === "basket" ?
                    <div className="absolute top-[-20px]  bg-blue-500 w-12 h-12 flex items-center justify-center rounded-full shadow-md ring-8 ring-white">
                        <FaShoppingBasket size={20} className="text-white" />
                    </div> : ""}
                <FaShoppingBasket size={20} className={`${selected === "basket" ? "disabled" : ""} `} />
                <span className="text-sm">הקורסים שלי</span>
            </NavLink>
            {/* </div> */}

            {/* <div className="absolute top-[-20px] right-1/2 transform translate-x-1/2 bg-blue-500 w-12 h-12 flex items-center justify-center rounded-full shadow-md ring-8 ring-white">
              <FaHome size={20} className="text-white" />
            </div>
       */}
            {/* <div
                onClick={() => handleSelect("home")}
                className="flex flex-col items-center text-gray-400 hover:text-blue-500 cursor-pointer "
            >
                {selected === "home" ?
                    <div className="absolute top-[-20px]  bg-blue-500 w-12 h-12 flex items-center justify-center rounded-full shadow-md ring-8 ring-white">
                        <FaHome size={20} className="text-white" />
                    </div> : ""
                }
                <FaHome size={20} className={`${selected === "home" ? "disabled" : ""} `} />
                <span className="text-sm">קורסים</span>
            </div> */}
                <NavLink to="" 
              
              onClick={() => handleSelect("home")}
                className="flex flex-col items-center text-gray-400 hover:text-blue-500 cursor-pointer "
            >
                {selected === "home" ?
                    <div className="absolute top-[-20px]  bg-blue-500 w-12 h-12 flex items-center justify-center rounded-full shadow-md ring-8 ring-white">
                        <FaHome size={20} className="text-white" />
                    </div> : ""
                }
                <FaHome size={20} className={`${selected === "home" ? "disabled" : ""} `} />
                <span className="text-sm">קורסים</span>
            </NavLink>
        </div>

















        //    <div
        //    onClick={() => handleSelect("edit")}
        //    className={`flex flex-col items-center text-gray-400 hover:text-blue-500 cursor-pointer transition-all duration-300 ${
        //      selected === "edit" ? "-translate-y-2 text-blue-500" : ""
        //    }`}
        //  >
        //    <FaEdit size={20} />
        //    <span className="text-sm">חתימות</span>
        //  </div>






        //     <div className="flex justify-between items-center bg-black text-white p-4 rounded-full w-full max-w-lg mx-auto shadow-lg relative">
        //     {/* <div className="flex items-center gap-4"> */}
        //       <div className="flex flex-col items-center text-gray-400 hover:text-blue-500 cursor-pointer">
        //         <FaEdit size={20} />
        //         <span className="text-sm">חתימות</span>
        //       </div>
        //       <div className="flex flex-col items-center text-gray-400 hover:text-blue-500 cursor-pointer">
        //         <FaShoppingBasket size={20} />
        //         <span className="text-sm">הקורסים שלי</span>
        //       </div>
        //     {/* </div> */}

        //     <div className="bg-blue-500 w-12 h-12 flex items-center justify-center rounded-full absolute top-[-15px] ring-2 ring-white shadow-md">
        //       <FaHome size={20} className="text-white" />
        //     </div>

        //     <div className="flex flex-col items-center text-gray-400 hover:text-blue-500 cursor-pointer">
        //       <FaHome size={20} />
        //       <span className="text-sm">קורסים</span>
        //     </div>
        //   </div>

    )
}

export default NavBar