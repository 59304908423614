import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Fillter from '../Fillter'
import DataTable from 'react-data-table-component'
import { BsPencil } from "react-icons/bs";
import { IoTrashOutline } from "react-icons/io5";
import { HeaderTable } from '../HeaderTable';
import {convertToIsraelTime} from '../../util/formatsForDate'
import './DataTableStyles.css'; // ייבוא קובץ ה-CSS

import ReportTrainingDaysFilter from './ReportTrainingDaysFilter';
const URL_EXCLE='admin/setting/report_rig_qualification_history_excel'
const GET_URL = 'admin/setting/report_rig_qualification_history'
const ReportTrainingDays = () => {
    // const [modalIsOpen, setModalIsOpen] = useState("");
    // const [id, setId] = useState("");
    const [fetchData, setFetchData] = useState([])
    const [loading, setloading] = useState(true)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
   
    const [dataFillter, setDataFillter] = useState({
       
                "member_name": "",
                "member_lastName": "",
                "member_identity": "",
                "member_number": "",
                "qul_name": "",
                "qul_number": "",
                "dateSt": "",
                "dateEn": "",
                "statusDescription": "",
                "trainingClusterName": "",
                "cluster_name": "",
                "cluster_number": "",
                "paying_id": "",

            }
    )


    const downloadExcel = async () => {
        try {
          const response = await axios.get(URL_EXCLE, {
            params: {
                ...dataFillter
              // member_number:"0001"
            },
            responseType: 'blob', // הגדרת התגובה כ-blob לצורך הורדת הקובץ
          });
      
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'report.xlsx');
          document.body.appendChild(link);
          link.click();
          link.remove();
        } catch (error) {
          console.error('Error downloading file:', error);
        }
      
        }


    const getData = async (page) => {
        // setloading(true);
        // await axios.get(`${GET_PAYING_CUSTOMERS_URL}s`).then(response => {
            await axios.get(GET_URL,{
                params:{
                    ...dataFillter,
                    "page":page
                    ,"size":perPage,
                    "sortBy":"h.member.firstName"
                    ,
                }})
        .then(response => {
            setFetchData(response?.data?.memberRigHistoryDto)
            setTotalRows(response?.data?.totalElements);
        })
        setloading(false)
        console.log(fetchData)}
    

    useEffect(()=>{
        getData(currentPage-1)
    },[currentPage,perPage,dataFillter])

    
    const handlePerRowsChange = async (newPerPage) => {
        setPerPage(newPerPage);

    }

    const handlePageChange = (page) => {
        // fetchUsers(page);
        // getData(page);
        setCurrentPage(page);
      };
    const columns = [
        // {
        //     name: "פעולות",

        //     cell: row => <div><button className=' border border-gray-500 p-2 opacity-15 hover:opacity-100' onClick={() => {
        //         // setnamecalss(row?.description)
        //         // setAction("edit")
        //         setId(row)
        //         setModalIsOpen("Edit")
        //     }
        //     }><BsPencil /></button>
        //         <button title="מחיקה" className=' border border-gray-500 ml-2 p-2 opacity-15 hover:opacity-100' onClick={() => {
        //             setId(row)
        //             setModalIsOpen("Delete")
        //         }}><IoTrashOutline /></button> </div>,
        //     style: {
        //         fontSize: '10px',
        //         justifyContent: 'center',

        //     },
        //     grow: 1,


        // },
        {
            name: "מספר תעודה",
            selector: (row) => row?.certificateNumber?.number,
            // style: {
            //     fontSize: '10px',
            //     justifyContent: 'center',
                
            // },
            // sortable: true,
        },


        {
            name: "סטטוס",
            
            selector: (row) => row.qualificationDay.statusQualification.statusDescription,
            // style: {
            //     fontSize: '16px',
            //     justifyContent: 'center',
            // },
            // sortable: true,


        },
        {
            name: "מספר לקוח משלם",
            // grow: 3,
            selector: (row) => row?.payingCustomer?.identity,
            // style: {
            //     fontSize: '16px',
            //     justifyContent: 'center'
            // },
            // sortable: true,
            // grow: 8,
        },

        {
            name: "שם לקוח משלם",
            cell: row => row?.payingCustomer?.name,

            // style: {
            //     fontSize: '10px',
            //     justifyContent: 'center',

            // },
            // grow: 3,


        },

        {
            name: "קוד נושא",
            cell: row => row.qualificationDay?.cluster?.number,

            // style: {
            //     fontSize: '10px',
            //     justifyContent: 'center',

            // },
            // grow: 3,


        },
        {
            name: "אשכול",
            cell: row => row.qualificationDay?.cluster?.name,

            // style: {
            //     fontSize: '10px',
            //     justifyContent: 'center',

            // },
            // grow: 3,


        },
        {
            name: "שווי ימי כשירות",
            cell: row => row?.qualificationDay?.days,

            // style: {
            //     fontSize: '10px',
            //     justifyContent: 'center',

            // },
            // grow: 3,


        },
        

        {
            name: "תאריך ההדרכה",
            cell: row => convertToIsraelTime(row?.qualificationDay?.startDate),

            // style: {
            //     fontSize: '10px',
            //     justifyContent: 'center',

            // },
            


        },
        {
            name: "מספר ההדרכה",
            cell: row => row?.qualificationDay.number,

            // style: {
            //     fontSize: '12px',
            //     justifyContent: 'center',

            // },
            // grow: 4,


        },
        {
            name: "שם ההדרכה",
            cell: row => row?.qualificationDay.name,

            // style: {
            //     fontSize: '12px',
            //     justifyContent: 'center',

            // },
            // grow: 2,


        },
        {
            name: "טלפון",
            cell: row => row?.member?.mainPhone,

            // style: {
            //     fontSize: '12px',
            //     justifyContent: 'center',

            // },
            // grow: 3,


        },
        {
            name: "אימייל",
            cell: row => row?.member?.email,

            // style: {
            //     fontSize: '12px',
            //     justifyContent: 'center',

            // },
            // grow: 3,


        },
        {
            name: "ת.ז",
            cell: row => row?.member?.idNumber,

            // style: {
            //     fontSize: '12px',
            //     justifyContent: 'center',

            // },
            // grow: 3,


        },
        {
            name: "שם משפחה",
            cell: row => row?.member?.lastName,

            // style: {
            //     fontSize: '12px',
            //     justifyContent: 'center',

            // },
            // grow: 3,


        },
        {
            name: "שם פרטי",
            cell: row => row?.member?.firstName,

            // style: {
            //     fontSize: '12px',
            //     justifyContent: 'center',

            // },
            // grow: 3,


        },



    ];



    const customStyles = {
        // header: {
        //     style: {
        //         justifyContent: 'center',

        //     },
        // },
        id: {
            style: {
                '&:2': { backgroundColor: '#EEEEEE' },
            }
        },
        // headRow: {
        //     style: {
              
        //         justifyContent: 'center',
        //         borderTopStyle: 'solid',
        //         borderTopWidth: '1px',fontSize: '14px',
                
        //         // borderTopColor: defaultThemes.default.divider.default,
        //     },
        // },
        headCells: {
            style: {

                fontSize: '14px',    
                
                borderRightStyle: 'solid',
                borderRightWidth: '1px',

            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {
                    
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    // borderRightColor: defaultThemes.default.divider.default,
                },
                justifyContent: 'center',
            },
        },
        rows: {
            style: {
                backgroundColor: '#FFFFFF',
                '&:active': {
                    backgroundColor: '#EEEEEE',
                },
            },
        },
    }


    return (
        <div className='w-full p-3 ' >
            <ReportTrainingDaysFilter setDataFillterout={setDataFillter} />
            
            <HeaderTable active={"דוח ימי כשירות"} downloadExcel={downloadExcel}></HeaderTable>
            <DataTable columns={columns} data={fetchData} pagination customStyles={customStyles} 
            
            noHeader={false}
    
    highlightOnHover
            
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressPending={loading} className='border' >
            </DataTable>


        </div>
    )
}

export default ReportTrainingDays