import axios from 'axios';
import React, { useState } from 'react'
import Input from '../../ui/Input';

const NewAddContact = ({ showModal, children, handleColse, getFilter, getDataTable, customer_id, row = "" }) => {
    const arrtel = ["קידומת", "050", "051", "052", "053", "054", "055", "057", "058", "059"]
    const GET_ADD_CONTACTS_URL = `admin/setting/contacts`

    const [formData, setFormData] = useState(row ? {
        name: row.name,
        phone: row.phone,
        office: row.office,
        email: row.email,
    }
        :
        {
            name: '',
            phone: '',
            office: '',
            email: '',
        });

    const [errors, setErrors] = useState({});

    const validateField = (name, value) => {
        let error = "";

        switch (name) {
            case "name":
                if (!value.trim()) {
                    error = "שם לקוח הינו שדה חובה.";
                }
                break;
            case "phone":
                if (!value.trim()) {
                    error = "הינו שדה חובה."
                }
                if (value.trim() && !/^\d+$/.test(value)) {
                    error = "מספר טלפון חייב להיות מספרים בלבד.";
                }
                break;
            case "email":
                if (!value.trim()) {
                    error = " הינו שדה חובה.";

                }
                else {
                    if (value.trim() && !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(value)) {
                        error = "כתובת דוא\"ל לא תקינה.";
                    }
                }
                break;
            case "office":
                if (value && !/^\d{5,7}$/.test(value)) {
                    error = "מיקוד צריך להיות מספר בן 5-7 ספרות.";
                }
                break;


            default:
                break;
        }

        return error;
    };

    // פונקציה לעדכון הערכים ובדיקת התקינות
    const handleChange = (e) => {
        const { name, value } = e.target;

        // עדכון הערך בטופס
        setFormData({ ...formData, [name]: value });

        // בדיקת שדה ספציפי בזמן הקלדה
        const fieldError = validateField(name, value);
        setErrors({ ...errors, [name]: fieldError });
    };



    // בדיקת תקינות של הטופס
    const validate = () => {
        const newErrors = {};

        if (!formData?.name?.trim()) {
            newErrors.name = "הינו שדה חובה.";
        }
        if (!formData?.phone?.trim()) {
            newErrors.id = "הינו שדה חובה."
        }
        if (formData?.phone?.trim() && !/^\d+$/.test(formData.phone)) {
            newErrors.phone = "מספר טלפון חייב להיות מספרים בלבד.";
        }
        if (!formData?.email?.trim()) {
            newErrors.email = "הינו שדה חובה.";
        }
        if (formData?.email?.trim() && !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(formData?.email)) {
            newErrors.email = "כתובת דוא\"ל לא תקינה.";
        }

        if (formData?.office && !/^\d{5,7}$/.test(formData.office)) {
            newErrors.office = "מיקוד צריך להיות מספר בן 5-7 ספרות.";
        }



        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // שליחת טופס
    const handleSubmit = (e) => {
        // e.preventDefault();

        if (validate()) {
            addNewPaying()
            // console.log("טופס נשלח בהצלחה:", formData);

        }
    };





    const handleEditClass = async (id) => {
        // const validationErrors = validate();
        if (validate()) {
            await axios.put(`${GET_ADD_CONTACTS_URL}/${id}`,
            
                formData
                , {
                    headers: { 'Content-Type': 'application/json' }
                }).then(response => {
                    if (response.status == 200) {
                        // setnamecalss("")
                        getDataTable()
                        handleColse()
                        console.log("Edit")
                        alert("עודכן");
                    }
                })
        }
    }

    const addNewPaying = async () => {
        const response = await axios.post(`${GET_ADD_CONTACTS_URL}/${customer_id?.id}`,
            //  JSON.stringify({

            //         name: formData.name,
            //         phone: formData.phone,
            //         office: formData.office,
            //         email: formData.email,
            //     })
            formData
            , {
                headers: { 'Content-Type': 'application/json' }
            })
        console.log(response)
        if (response?.status == 200) {
            // setnamecalss("")
            alert("הטופס נשלח בהצלחה!");
            await getDataTable()
            // await getFilter()
            handleColse()
        }
        else {
            console.log("error add class")
        }
    }



    return (
        <div className='absolute top-0 h-screen overflow-y-auto left-0 flex flex-col items-center pt-2 w-full drop-shadow-2xl shadow-2xl bg-[#FDFFFC]/50 '
            onClick={() => {
                // handleColse()
            }}>
            <div className='bg-white w-1/2 rounded-lg divide-y-2  divide-slate-500 border-0 border-emerald-50 '>
                <div className='w-full bg-[#0d406b] rounded-t-md flex justify-end border-[#0d406b] border-2'>
                    <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={() => {
                        handleColse()
                    }} >x</button>
                </div>
                <form className='max-md:w-[600px] m-[30px auto]  p-2 flex flex-col  ' style={{ direction: "rtl" }} >


                    <Input
                        req={true}
                        label='שם איש הקשר'
                        type="text"
                        id="name"
                        name="name"
                        error={errors.name}
                        className='  grid col-span-5 border'
                        value={formData?.name}
                        onChange={handleChange} />

                    <div className='grid grid-cols-10'>
                        <label className='grid col-span-3' >טלפון </label>
                        <input
                            className='grid col-span-3  border'
                            type="number"
                            name="phone"
                            value={formData?.phone}
                            onChange={handleChange}
                        />
                        <select className='border grid col-span-2 '>
                            {
                                arrtel.map((index) => {
                                    return (<option className='w-full'>
                                        {index}
                                    </option>)
                                })
                            }
                        </select>
                    </div>



                    <Input
                        label='אימייל'
                        req={true}
                        type="text"
                        id="email"
                        name="email"
                        className='  grid col-span-5 border'
                        value={formData.email}
                        error={errors.email}
                        onChange={handleChange}
                    />


                    <Input
                        label='תפקיד'
                        req={true}
                        className='grid col-span-5  border'
                        type="text"
                        id="office"
                        name="office"
                        error={errors.office}
                        value={formData.office}
                        onChange={handleChange}
                    />




















                </form >
                <div className='w-full p-2   flex justify-end'>
                    <button className='border bg-green-500 hover:bg-green-300 p-1 px-3' onClick={() => {
                        // if (nameRole != "") {
                        // row ? handleEditRole(row.id) :
                        // addNewMember()
                        {
                            row ? handleEditClass(row.id) : handleSubmit()
                        }
                        // handleSubmit()
                        // }


                    }}>שמור</button>
                    <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3' onClick={() => {
                        // reset()
                        // setShowModal(false)
                        handleColse()
                    }}>בטל</button>
                </div>
            </div >
        </div >
    );
};


export default NewAddContact