import React, { useEffect, useState } from 'react'
// import axios from 'axios'
import Fillter from '../Fillter'
import Select from "react-select"


const MemberFilter = ({setDataFillterout,dataforFILTER }) => {
    const [dataFillter, setDataFillter] = useState({
        "firstName": "",
        "lastName": "",
        "idNumber": "",
        "statusId": null,
        "status_id_labal": null,
        "pay_id": "",
        "pay_id_labal": "",
        "dateEn": "",
        "number": "",
        "dateSt": "",
    })
    const restart = async () => {
        setDataFillter(
            {
                "firstName": "",
                "lastName": "",
                "idNumber": "",
                "statusId": "",
                "status_id_labal": "",
                "pay_id": "",
                "pay_id_label": "",
                "dateEn": "",
                "number": "",
                "dateSt": "",
            }
        )
        setDataFillterout({  "firstName": "",
            "lastName": "",
            "idNumber": "",
            "statusId": "",
            "pay_id": "",
            "pay_id_label": "",
            "dateEn": "",
            "number": "",
            "dateSt": "",})
        // await getPaying_customersFilter()
    }

    const handleChangeDate = (e) => {
        const { name, value } = e.target;
        setDataFillter({
            ...dataFillter,
            [name]: value
        });
    }




    const handleChange = (selectedOption,name,) => {
        console.log(selectedOption)
        // const { name } = e.name;
        setDataFillter({
            ...dataFillter,
            [name]: selectedOption?.value
            ,
        })
        
       
  
    }
    const handleChange2 = (selectedOption,name,name2) => {
        // const { name } = e.name;
        
        setDataFillter({
            ...dataFillter,
            [name]: selectedOption?.value,
            [name2]: selectedOption?.label  ,
        })
  
    }
    const actionFilter = () => {
        setDataFillterout(dataFillter)
    }
    // useEffect(() => {
    //     console.log(dataFillter)
    // }, [dataFillter])
    return (
        <Fillter actionFilter={actionFilter} restart={restart}>
            <div className='grid grid-cols-2 '>
                <div className='grid col-span-1 gap-y-2'>
                    <div className='flex justify-end items-center'>
                             <Select className=' w-2/3 text-right '
                            // options={formattedClients} value={""} 
                            value={dataFillter.lastName?
                                { value: dataFillter.lastName, label: dataFillter.lastName }:null}
                            onChange={(e)=>handleChange(e,'lastName')}
                            options={dataforFILTER?.lastNames?.map((item) => { return { value: item, label: item } })}
                            placeholder="...חפש"
                            isClearable
                            isSearchable

                            maxMenuHeight={100}
                        >
                            {/* {dataForfilterScrach.map((choes) => (
                                <option key={choes.identity} value={choes.identity}>
                                    {choes.identity}
                                </option>
                            ))} */}
                        </Select >
                        <label className='ml-3 w-1/6' htmlFor="identity">שם משפחה</label>
                    </div>
                    <div className='flex justify-end items-center'>
                            <Select className=' w-2/3 text-right '
                            // type="text"
                            id="statusId"
                            name="statusId"
                            value={dataFillter.status_id_labal?
                                { value: dataFillter.status_id_labal, label: dataFillter.status_id_labal }:null}
                            onChange={(e)=>handleChange2(e,'statusId','status_id_labal')}
                            options={dataforFILTER?.statuses?.map((item) => { return { value: item.statusId, label: item.statusDescription } })}
                            placeholder="בחר"
                            isClearable
                            isSearchable

                            maxMenuHeight={100} />

                        <label className='ml-3 w-1/6' htmlFor="identity">סטטוס</label>
                    </div>
                    <div className='flex justify-end items-center'>
                        <Select className=' w-2/3 text-right '
                            type="text"
                            id="pay_id"
                            name="pay_id"
                            value={dataFillter.pay_id_labal?
                                { value: dataFillter.pay_id_labal, label: dataFillter.pay_id_labal }:null}
                            onChange={(e)=>handleChange2(e,'pay_id',"pay_id_labal")}
                            options={dataforFILTER?.scrollPaying?.map((item) => { return { value: item.id, label: item.display } })}
                            placeholder="...חפש"
                            isClearable
                            isSearchable
                            maxMenuHeight={100} />
                            

                        <label className='ml-3 w-1/6' htmlFor="number">לקוח משלם</label>
                    </div>

                </div>
                <div className='grid col-span-1 '>
                    <div className='flex justify-end items-center'>
                             <Select className=' w-2/3 text-right '
                         type="text"
                         id="firstNames"
                         name="firstNames"
                         value={dataFillter.firstName?
                             { value: dataFillter.firstName, label: dataFillter.firstName }:null}
                         onChange={(e)=>handleChange(e,'firstName')}
                         options={dataforFILTER?.firstNames?.map((item) => { return { value: item, label: item } })}
                         placeholder="...חפש"
                         isClearable
                         isSearchable

                         maxMenuHeight={100}
                        
                        
                        
                        />


                        <label className='ml-3 w-1/6' htmlFor="instructorId">שם פרטי</label>
                    </div>
                    <div className='flex justify-end items-center'>
                            <Select className=' w-2/3 text-right '
                            type="text"
                            id="idNumber"
                            name="idNumber"
                            value={dataFillter.idNumber?
                                { value: dataFillter.idNumber, label: dataFillter.idNumber }:null}
                            onChange={(e)=>handleChange(e,'idNumber')}
                            options={dataforFILTER?.ids?.map((item) => { return { value: item, label: item } })}
                            placeholder="...חפש"
                            isClearable
                            isSearchable

                            maxMenuHeight={100}
                           
                           />


                        <label className='ml-3 w-1/6' htmlFor="statusQId">ת.ז</label>
                    </div>
                    <div className='flex justify-end items-center'>
                             <Select className=' w-2/3 text-right '
                          type="text"
                          id="number"
                          name="number"
                          value={dataFillter.number?
                              { value: dataFillter.number, label: dataFillter.number }:null}
                          onChange={(e)=>handleChange(e,'number')}
                          options={dataforFILTER?.numbers?.map((item) => { return { value: item, label: item } })}
                          placeholder="...חפש"
                          isClearable
                          isSearchable

                          maxMenuHeight={100} 
                            />


                        <label className='ml-3 w-1/6' htmlFor="name">מספר חבר</label>
                    </div>


                </div>

            </div>
            {/* className="p-2 outline-none focus:ring-2 focus:ring-blue-500 w-full" */}
            <div className='w-full m-2 flex justify-end items-center'>
                <div className='w-1/3  flex justify-end '>
                    <input className='border border-black  text-right p-2 mr-2' placeholder="מתאריך"
                        type="date"
                        id="dateEn"
                        name="dateEn"
                        value={dataFillter.dateEn}
                        onChange={handleChangeDate} />


                    <input className='border w-1/3 border-black text-right p-2' placeholder='חפש'
                        type="date"
                        id="dateSt"
                        name="dateSt"
                        value={dataFillter.dateSt}
                        onChange={handleChangeDate} />

                </div>
                <label className=' w-1/6' htmlFor="date">תאריך הרשמה</label>
            </div>
        </Fillter>
    )
}

export default MemberFilter