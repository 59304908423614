import React, { useEffect, useState } from 'react'
import { HeaderTable } from '../../HeaderTable'
import DataTable from 'react-data-table-component'
import axios from 'axios'

const Summary_purchased_used_products_payCustomer = ({ row }) => {
    const [fetchData, setFetchData] = useState([])
    const [coun1, setCoun1] = useState("")
    const [coun2, setCoun2] = useState("")
    const [loading, setloading] = useState(true)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1)
    
    
    
    const [fetchData2, setFetchData2] = useState([])
    // const [loading2, setloading2] = useState(true)
    // const [totalRows2, setTotalRows2] = useState(0);
    // const [perPage2, setPerPage2] = useState(10);
    // const [currentPage2, setCurrentPage2] = useState(1)
    const GET_URL = `admin/setting/summary_purchased_used_products_payCustomer/${row.id}`
    console.log(row)


    const getDataTable = async (page) => {
        setloading(true)
        await axios.get(GET_URL, {
            params: {
                "page": page
                , "size": perPage,
            }
        })
            .then(response => {
                if (response.status == 200) {
                    setFetchData(response?.data.products_purchased)
                    setFetchData2(response?.data.products_used)
                    setCoun1(response.data.sum_used_total)
                    setCoun2(response.data.bank_now)
                    // setdataForfilterScrach(response?.data)
                    // setTotalRows(response.data.totalElements);
                }
            })
        setloading(false)

    }




    const handlePerRowsChange = async (newPerPage) => {
        setPerPage(newPerPage);

    }

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setDataFillter({
    //         ...dataFillter,
    //         [name]: value
    //     });
    // }
    // const handlePageChange = (page) => {
    //     // fetchUsers(page);
    //     // getData(page);
    //     setCurrentPage(page);
    // };
    useEffect(() => {
        getDataTable(currentPage - 1)
    }, [])


    const columns = [
        {
            name: "סכום בשקלים",
            cell: row => `${row?.totalPrice} ₪`,

            style: {
                fontSize: '10px',
                justifyContent: 'center',

            },
            // grow: 3,


        },
        {
            name: "סה\"כ נרכשו",
            // grow: 3,
            selector: (row) => row?.amount,
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,
        },
        {
            name: "קוד מוצר",
            // grow: 2,
            selector: (row) => row.code,
            style: {
                fontSize: '10px',
                justifyContent: 'center',

                // width: '100px'
            },
            sortable: true,
        },




        {
            name: "שם מוצר",
            // grow: 3,
            selector: (row) => row.name,
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,


        },






    ];
    const columns2 = [
        {
            name: "סכום בשקלים",
            cell: row => `${row?.totalPrice} ₪`,

            style: {
                fontSize: '10px',
                justifyContent: 'center',

            },
            // grow: 3,


        },
        {
            name: "סה\"כ נוצלו",
            // grow: 3,
            selector: (row) => row?.amount,
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,
        },
        {
            name: "קוד מוצר",
            // grow: 2,
            selector: (row) => row.code,
            style: {
                fontSize: '10px',
                justifyContent: 'center',

                // width: '100px'
            },
            sortable: true,
        },




        {
            name: "שם מוצר",
            // grow: 3,
            selector: (row) => row.name,
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,


        },






    ];



    const customStyles = {
        header: {
            style: {

                justifyContent: 'center',

            },
        },
        id: {
            style: {
                '&:2': { backgroundColor: '#EEEEEE' },
            }
        },
        headRow: {
            style: {
                justifyContent: 'center',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                // borderTopColor: defaultThemes.default.divider.default,
            },
        },
        headCells: {
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                // borderRightColor: defaultThemes.default.divider.default,

            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {

                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    // borderRightColor: defaultThemes.default.divider.default,
                },
            },
        },
        rows: {
            style: {
                backgroundColor: '#FFFFFF',
                '&:active': {
                    backgroundColor: '#EEEEEE',
                },
            },
        },
    }









    return (
        <div className='w-full p-3' style={{ direction: "ltr" }}>
            {/* ארז אמר שמיותר */}
            {/* <HeaderTable active={"סיכום מוצרים שנרכשו"}></HeaderTable>

            <DataTable columns={columns} data={fetchData} pagination
                customStyles={customStyles}
                // paginationServer
                // paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                // onChangePage={handlePageChange}
                progressPending={loading} className='border' >

            </DataTable> */}

            <HeaderTable active={"סיכום מוצרים מומשו"}></HeaderTable>
            <DataTable columns={columns2} data={fetchData2} pagination
                customStyles={customStyles}
                // paginationServer
                // paginationTotalRows={totalRows2}
                // onChangeRowsPerPage={handlePerRowsChange}
                // onChangePage={handlePageChange}
                progressPending={loading} className='border' >

            </DataTable>

        <p>{`סכום שנוצל: ${coun1}₪`}</p>
        {/* <p>{`סהכ כסף שנשאר בקופה עכשיו: ${coun2}₪`}</p> */}
        <p>{`יתרה כספית לגרום משלם: ${coun2}₪`}</p>
        </div>
    )
}

export default Summary_purchased_used_products_payCustomer