import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './PaymentFailure.css';

const PaymentFailure = ({onClose,error}) => {
  const navigate = useNavigate();
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    setShowAnimation(true);
  }, []);

  const handleRedirect = () => {
    // navigate('/Mobile');
    onClose()
  };

  return (
    <div className="failure-container">
      {showAnimation && (
        <div className="failure-animation">
          <div className="circle">
            <div className="cross">✕</div>
          </div>
          <p className="failure-message">התשלום נכשל</p>
          {/* <p className="failure-reason">פרטי האשראי שהוזנו שגויים או שאין מספיק יתרה</p> */}
          <p className="failure-reason">{error}</p>
          <button className="retry-button" onClick={handleRedirect}>אישור</button>
        </div>
      )}
    </div>
  );
};

export default PaymentFailure