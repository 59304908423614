import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import { BsPencil } from "react-icons/bs";
import { IoTrashOutline } from "react-icons/io5";
import ModalEdit from '../ModalEdit';
import ModalNewUser from './ModalNewUser';
import { HeaderTable } from '../HeaderTable';
const GET_URL = 'admin/setting/employees'



const Users = () => {
  const [modalIsOpen, setModalIsOpen] = useState("");
  const [id, setId] = useState();
  const [fetchData, setFetchData] = useState([])
  const [loading, setloading] = useState(true)
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const getUsers = async (page) => {


    await axios.get(GET_URL, {
      params: {
        "page": page
        , "size": perPage,
      }
    })
      .then(response => {
        setFetchData(response?.data)
        setTotalRows(response?.data?.totalElements);
      })
    setloading(false)
    console.log(fetchData)
  }




  const handleColse = () => {
    setModalIsOpen("")
  }


  const handleDeteleUser = async () => {

    await axios.delete(`${GET_URL}/${id.id}`).then(response => {
      if (response.status == 200) {
        handleColse()
        getUsers()
        console.log("ok")
      }
    })
  }






  useEffect(() => {
    getUsers(currentPage - 1)
  }, [currentPage, perPage])

  const handlePerRowsChange = async (newPerPage) => {
    setPerPage(newPerPage);

  }


  const handlePageChange = (page) => {

    setCurrentPage(page);
  };






  const columns = [
    {
      name: "פעולה",

      cell: row => <div><button className=' border border-gray-500 p-2 opacity-15 hover:opacity-100' onClick={() => {
        setId(row)
        setModalIsOpen("Edit")
      }
      }><BsPencil /></button>
        <button title="מחיקה" className=' border border-gray-500 ml-2 p-2 opacity-15 hover:opacity-100' onClick={() => {
          setId(row)
          setModalIsOpen("Delete")
        }}><IoTrashOutline /></button> </div>,
      //handleDeteleClass(row?.id)
      style: {
        fontSize: '16px',
        justifyContent: 'center',

      },
      grow: 2,


    },
    {
      name: "קבוצה",
      grow: 2,
      selector: (row) => row.group,
      style: {
        fontSize: '16px',
        justifyContent: 'center',
        width: '100px'
      },
      sortable: true,
    },
    {
      name: "תפקיד",
      grow: 2,
      selector: (row) => row?.job?.description,
      style: {
        fontSize: '16px',
        justifyContent: 'center',
        width: '100px'
      },
      sortable: true,
    },
    {
      name: "מחלקה",
      grow: 2,
      selector: (row) => row?.department?.description,
      style: {
        fontSize: '16px',
        justifyContent: 'center',
        width: '100px'
      },
      sortable: true,
    },
    {
      name: "טלפון",
      grow: 2,
      selector: (row) => row?.phone,
      style: {
        fontSize: '16px',
        justifyContent: 'center',
        width: '100px'
      },
      sortable: true,
    },
    {
      name: "איימל",
      grow: 2,
      selector: (row) => row.email,
      style: {
        fontSize: '16px',
        justifyContent: 'center',
        width: '100px'
      },
      sortable: true,
    },



  ];
  const customStyles = {
    header: {
      style: {

        justifyContent: 'center',

      },
    },
    id: {
      style: {
        '&:2': { backgroundColor: '#EEEEEE' },
      }
    },
    headRow: {
      style: {
        justifyContent: 'center',
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        // borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {

        justifyContent: 'center',
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        // borderRightColor: defaultThemes.default.divider.default,

      },
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {

          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    rows: {
      style: {
        backgroundColor: '#FFFFFF',
        '&:active': {
          backgroundColor: '#EEEEEE',
        },
      },
    },
  }
  useEffect(() => {
    if (modalIsOpen) {
        document.body.style.overflow = 'hidden'; // Disable body scrolling
      } else {
        document.body.style.overflow = 'auto'; // Enable body scrolling
      }
  
      return () => {
        document.body.style.overflow = 'auto'; // Cleanup: ensure body scroll is reset
      };
    // setHide(modalIsOpen)
}, [modalIsOpen])
  return (
    <div className='w-full p-3'>
      <HeaderTable active={"משתמשים"} setModalIsOpen={setModalIsOpen} />
      <DataTable
        columns={columns} data={fetchData} pagination customStyles={customStyles}
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        progressPending={loading} className='border' >

      </DataTable>

      {modalIsOpen == "New" && <ModalNewUser showModal={modalIsOpen == "New"} handleColse={handleColse} getDepartment={getUsers}>
      </ModalNewUser>}
      {modalIsOpen == "Edit" && <ModalNewUser showModal={modalIsOpen == "Edit"} handleColse={handleColse} getDepartment={getUsers} row={id}>
      </ModalNewUser>}
      {modalIsOpen == "Delete" && <ModalEdit showModal={modalIsOpen == "Delete"} handleColse={handleColse} handleDetele={handleDeteleUser}></ModalEdit>}
    </div>
  )
}

export default Users