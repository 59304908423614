import React, { useState } from 'react';
import './Stepper.css';
import Signature from './Signature';
// import axios from 'axios';

const Stepper = ({getLocation , location ,  steps, currentStep,getDataSignatures}) => {
  
  return (
    // <div className="stepper-container w-full">
    //   <p className="progress-text">
    //     חתימה: {currentStep} מתוך {steps.length}
    //   </p>
    //   <div className="stepper">
    //     {steps.map((step, index) => (
    //       <div key={index} className="step">
    //         <div className={`circle ${step.status}`}></div>
    //         {index !== steps.length - 1 && (
    //           <div className={`connector ${steps[index + 1].status}`}></div>
    //         )}
    //         <p className={`step-label ${step.status}`}>{step.label}</p>
    //       </div>
    //     ))}
    //   </div>
    // </div>
    <div className='w-full'>{currentStep != steps?.length && <div className='w-full mb-10 flex justify-between '>
      {steps?.indexSignatures?.map((step, index) => (
        <div key={index} className={` flex justify-center items-center relative w-full before:content-[''] ${currentStep > index-1? "before:bg-blue-800" : "before:bg-gray-100"}  before:w-full before:h-[3px] before:absolute before:right-1/2 before:top-1/2  before:-translate-y-1/2  before:first:hidden`} >
          <div className={`${step?.colorSign != "GRAY" ? `${step?.colorSign} w-9 h-9 text-blue-600 bg-white` : "text-gray-300 w-7 h-7 bg-gray-100"}  flex justify-center items-center rounded-full relative z-10`}>{index + 1}</div>
        </div>))}
    </div>}
      <Signature getLocation ={getLocation} location = {location} getDataSignatures={getDataSignatures} steps={steps} />
    </div>
  );
};

export default Stepper;