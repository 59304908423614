import React from 'react'
import axios from 'axios'

import ExcleButtonDownload from './ui/ExcleButtonDownload';

export const HeaderTable = ({active,downloadExcel='',setModalIsOpen="" ,titleBtn="חדש",howmach}) => {
    
  

  


  return (
<   div className='border border-solid rounded-t-md  border-[#0d406b] '>
       
       <div className='bg-[#0d406b] text-right text-white font-semibold text-[20px] p-2 font-sans grid grid-cols-2 rounded-t-lg border items-center  '>
       <div className='flex justify-end'>
         {downloadExcel&&<ExcleButtonDownload downloadExcel={downloadExcel}/>}
       {setModalIsOpen&&<button className='bg-[#1e8e2b] border border-[#1a7925] text-white w-fit h-fit flex justify-self-end self-center p-2 px-3 rounded-lg' onClick={()=>{setModalIsOpen("New")}}>
         {/* חדש+ */}
       {titleBtn +"+"}
       </button>}
       </div>
       <div className='flex flex-col '>
       <p className='p-2' >{active}</p>
        <p className='mr-3'>{howmach}
          </p></div>
       
       
       </div>
       </div>




    // <div className='border border-solid rounded-t-md border-[#0d406b]  '>
    //      {/* <div className='bg-[#0d406b] text-right text-white font-semibold text-[20px] p-2 font-sans grid grid-cols-2 rounded-t-lg border items-center  '> */}
    // <div className='bg-[#0d406b] text-right text-white font-semibold text-[20px] p-4 font-sans flex justify-end rounded-t-lg border  '>
    // {downloadExcel&& <ExcleButtonDownload downloadExcel={downloadExcel}/>}


    // <p>{active}</p>
    
    // </div>
    // </div>
  )
}


// <button className={loading?'bg-red-500 ':'bg-green-500 '} onClick={async()=>{
//       setLoading(true)
      
//       await downloadExcel()
//       setLoading(false)
//       }}> ייצוא {loading&&<div>...טעינה</div>}</button>