import React from 'react'
import './CourseStatusCard.css';

const CourseStatusCard = ({ courseName, date, status }) => {
    const getStatusColor = (status) => {
        switch (status) {
          case 'ברישום':
            return 'orange';
          case 'בוטל':
            return 'red';
          case 'הסתיים':
            return 'green';
          default:
            return 'gray';
        }
      };
    return (
        <div className={`course-card ${getStatusColor(status)}`}>
        {/* <div className={`course-card ${status=="ברישום"?"bg-orange-500":status=="ביטול"?"bg-red-600":"bg-green-600"}`}> */}
          <div className="course-header">
            <button className="expand-btn">+</button>
            <span className="course-name">{courseName}</span>
          </div>
          <div className="course-details">
            <span className="course-status-label">סטטוס:</span>
            <span className="course-status">{status}</span>
            <span className="course-date">{date}</span>
          </div>
        </div>
  )
}

export default CourseStatusCard