import React, { useEffect, useState } from 'react'
import { HeaderTable } from '../../HeaderTable'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import { convertToIsraelTime, formatOnlyTime } from '../../../util/formatsForDate'

const Purchase_archive = ({ row }) => {
    const [fetchData, setFetchData] = useState([])
    const [loading, setloading] = useState(true)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1)
    const GET_URL = `admin/setting/paying_customer/transaction/${row?.id}`
    const URL_EXCLE = `admin/setting/paying_customer/transaction_excel/${row?.id}`


    const getDataTable = async (page) => {
        setloading(true)
        await axios.get(GET_URL, {
            params: {
                "page": page
                , "size": perPage,
            }
        })
            .then(response => {
                if (response.status == 200) {
                    setFetchData(response?.data?.transactionPayCustomerDto)
                    // setdataForfilterScrach(response?.data)
                    setTotalRows(response.data.totalElements);
                }
            })
        setloading(false)

    }




    const handlePerRowsChange = async (newPerPage) => {
        setPerPage(newPerPage);

    }

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setDataFillter({
    //         ...dataFillter,
    //         [name]: value
    //     });
    // }
    const handlePageChange = (page) => {
        // fetchUsers(page);
        // getData(page);
        setCurrentPage(page);
    };
    useEffect(() => {
        getDataTable(currentPage - 1)
    }, [])


    const columns = [

        {
            name: "פעולות",
            // grow: 2,
            selector: (row) => row.product_code,
            style: {
                fontSize: '10px',
                justifyContent: 'center',

                // width: '100px'
            },
            sortable: true,
        },


        {
            name: "הערות",
            grow: 1,
            selector: (row) => <div className=''
                style={
                    {
                        // padding:"auto",
                       
                        direction:"rtl",
                        whiteSpace: "normal",
                        // flexWrap:"wrap",
                        overflow: "hidden",
                    }
                }
            >{row?.note}</div>,
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                
                // width: '100px'
            },
            sortable: true,


        },
        {
            name: "מספר קבלה",
            // grow: 3,
            selector: (row) => row?.invoice,
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,
        },
        // ארז אמר מיותר
        // {
        //     name: "מספר חשבונית",
        //     cell: row => row?.transactionId,

        //     style: {
        //         fontSize: '10px',
        //         justifyContent: 'center',

        //     },
        //     // grow: 3,


        // },

        {
            name: "סה\"כ לתשלום",
            cell: row => row?.amountPay,

            style: {
                fontSize: '10px',
                justifyContent: 'center',

            },
            // grow: 2,


        },
        {
            name: "כמות",
            // grow: 3,
            selector: (row) => row?.quantity,
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,
        },
        {
            name: "מחיר ליחידה",
            // grow: 3,
            selector: (row) => row?.product?.price,
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,
        },
        {
            name: "קוד מוצר",
            // grow: 3,
            selector: (row) => row?.product?.code,
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,
        },
        {
            name: "שם מוצר",
            // grow: 3,
            selector: (row) => row?.product?.name,
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,
        },
        {
            name: "שעה",
            // grow: 3,
            selector: (row) => formatOnlyTime(row?.datePayment),
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,
        },
        {
            name: "תאריך",
            // grow: 3,
            selector: (row) => convertToIsraelTime(row?.datePayment),
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,
        },




    ];



    const customStyles = {
        header: {
            style: {

                justifyContent: 'center',

            },
        },
        id: {
            style: {
                '&:2': { backgroundColor: '#EEEEEE' },
            }
        },
        headRow: {
            style: {
                justifyContent: 'center',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                // borderTopColor: defaultThemes.default.divider.default,
            },
        },
        headCells: {
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                // borderRightColor: defaultThemes.default.divider.default,
                padding:"10px"
            },
        },
        cells: {
            style: {
                // '&:not(:last-of-type)': {
                // width:"50px",
                // height: '48px',
                // wordWrap: 'break-word', // Ensures the text wraps within the cell
                // whiteSpace: 'pre-wrap', // Maintains whitespace and line breaks
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                padding:"10px"

                // borderRightColor: defaultThemes.default.divider.default,
                // },
            },
        },
        rows: {
            style: {
                
                minHeight: '30px',
                backgroundColor: '#FFFFFF',
                '&:active': {
                    backgroundColor: '#EEEEEE',
                },
            },
        },
    }



    const downloadExcel = async () => {
        try {
            const response = await axios.get(URL_EXCLE, {
                // params: {
                //     ...dataFillter
                //   // member_number:"0001"
                // },
                responseType: 'blob', // הגדרת התגובה כ-blob לצורך הורדת הקובץ
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'report.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading file:', error);
        }

    }





    return (
        <div className='w-full p-3' style={{ direction: "ltr" }}>

            <HeaderTable active={"ארכיון רכישות"} downloadExcel={downloadExcel}></HeaderTable>

            <DataTable columns={columns} data={fetchData} pagination
                customStyles={customStyles}
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressPending={loading} className='border'
                // style={{width: "50%;",
                //     maxWidth: "70%",
                //     overflowX: "auto"  
                //     }} 
                    >
                
            </DataTable>


        </div>

    )
}


export default Purchase_archive