import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import NavBar from '../copmponents/NavBar'
import UserProfile from '../copmponents/UserProfile'

const Mobile = () => {
  const [loading,setLoading]=useState(false)
  setTimeout(()=>{setLoading(true)},1000)
  return (
    <div className='h-[93vh] p-4'>
      {loading&&<UserProfile/>}
           <Outlet/>
        <NavBar/>
        </div>
        
  )
}

export default Mobile