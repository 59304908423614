import React, { useState,useEffect } from 'react'
import Card from '../Card'
import DataTable from 'react-data-table-component'
import { BsPencil } from "react-icons/bs";
import { IoTrashOutline } from "react-icons/io5";
import axios from 'axios'

import ModalEdit from '../ModalEdit';
import ModalNewProducts from './ModalNewProducts';
import { HeaderTable } from '../HeaderTable';


const GET_PRODUCT_URL='admin/setting/product'
const Products = () => {

  const [modalIsOpen, setModalIsOpen] = useState("");
    const [id, setId] = useState("");
    const [loading, setloading] = useState(true)


    
    const [fetchData, setFetchData] = useState([])
  
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const handleColse = () => {
        setModalIsOpen("")
    }


  useEffect(()=>{
    getProduct()
  },[])



  const handleDeteleProduct=async()=>{
    
    await axios.delete(`${GET_PRODUCT_URL}/${id.id}`).then(response => {
    if(response.status==200)
      { 
        if(response.status==200)
        {
          handleColse()
          getProduct()
          console.log("ok")
        }
      }
    })}
    
    const getProduct=async()=>{

      await axios.get(GET_PRODUCT_URL).then(response => {
        setFetchData(response?.data)
        console.log(response?.data)
      })
       
        setloading(false)
      
      }

    
      const handleEditProduct=async()=>{
        
        await axios.put(`${GET_PRODUCT_URL}/${id}`,JSON.stringify({'des': ""}), {
          headers: { 'Content-Type': 'application/json' }  
      }).then(response => {
          if(response.status==200)
          {
            getProduct()
            console.log("Edit")
          }
        })} 
      
        
    
    
  const columns = [
    {
      name: "פעולות",
      
      cell: row => <div><button className=' border border-gray-500 p-2 opacity-15 hover:opacity-100' onClick={() => {
        // setnamecalss(row?.description)
        // setAction("edit")
        setId(row)
        setModalIsOpen("Edit")
    }
    }><BsPencil /></button>
        <button title="מחיקה" className=' border border-gray-500 ml-2 p-2 opacity-15 hover:opacity-100' onClick={() => {
            setId(row)
            setModalIsOpen("Delete")
        }}><IoTrashOutline /></button> </div>,
      style: {
        fontSize: '16px',
        justifyContent: 'center',
        
      },
      grow:1,
        
      
  },
  {
    name: "מחיר",
    grow: 3,
    selector:(row)=>row.price,
    style: {
      fontSize: '16px',
      justifyContent: 'center',
      width: '100px'},
      sortable: true,
},
  {
    name: "קוד המוצר",
    cell:(row)=>row.code,

    style: {
      fontSize: '16px',
      justifyContent: 'center',
      
    },
    grow:3,
      
    
},
{
  name: "שם המוצר",
  cell:(row)=>row.name,

  style: {
    fontSize: '16px',
    justifyContent: 'center',
    
  },
  grow:3,
    
  
},



  
];



const customStyles = {
  header: {
    style: {

      justifyContent: 'center',
      
    },
  },
  id:{
    style:{
      '&:2': {backgroundColor: '#EEEEEE' },}},
  headRow: {
    style: {
      justifyContent: 'center',
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      // borderTopColor: defaultThemes.default.divider.default,
    },
  },
  headCells: {
    style: {
        
        justifyContent: 'center',
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        // borderRightColor: defaultThemes.default.divider.default,
      
    },
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        // borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
   rows: {
  style: {
    backgroundColor: '#FFFFFF',
    '&:active': {
      backgroundColor: '#EEEEEE',
    },
  },
},
}

  
  
  
  
  
  
  
useEffect(() => {
  if (modalIsOpen) {
      document.body.style.overflow = 'hidden'; // Disable body scrolling
    } else {
      document.body.style.overflow = 'auto'; // Enable body scrolling
    }

    return () => {
      document.body.style.overflow = 'auto'; // Cleanup: ensure body scroll is reset
    };
  // setHide(modalIsOpen)
}, [modalIsOpen])
  
  
  
  return (
    <div className='w-full p-3'>
       
        <HeaderTable  active={"מוצרים"} setModalIsOpen={setModalIsOpen} />
        <DataTable columns={columns} data={fetchData} pagination customStyles={customStyles}  progressPending={loading} className='border' >
        </DataTable>
        {modalIsOpen == "New" && <ModalNewProducts showModal={modalIsOpen == "New"} handleColse={handleColse} getDepartment={getProduct}>
            </ModalNewProducts>}
            {modalIsOpen == "Edit" && <ModalNewProducts showModal={modalIsOpen == "Edit"} handleColse={handleColse} getDepartment={getProduct} row={id}>
            </ModalNewProducts>}
            {modalIsOpen == "Delete" && <ModalEdit showModal={modalIsOpen == "Delete"} handleColse={handleColse} handleDetele={handleDeteleProduct}></ModalEdit>}
    </div>
  )
}

export default Products