import React, { useEffect, useState } from 'react'
import Modall from '../Modall'
import axios from 'axios'
import InputUniversal from '../ui/InputUniversal'
const GET_CLASS_URL = 'admin/setting/department'

const ModalNewClass = ({ showModal, children, handleColse,getDepartment,row="" }) => {
    const [nameclass, setnamecalss] = useState("")
    const [error, setError] = useState("")
    useEffect(()=>{
        if(row)
        {
            setnamecalss(row.description)
        }
    },[])




    const handleCheck=(name)=>{
        if(!name?.target?.value)
        {
            setError("שם המחלקה ריק")
        }
        else
        {setError("")
    }
        setnamecalss(name?.target?.value)
        
    }




    const handleEditClass = async (id) => {

        await axios.put(`${GET_CLASS_URL}/${id}`, JSON.stringify({ 'des': nameclass }), {
          headers: { 'Content-Type': 'application/json' }
        }).then(response => {
          if (response.status == 200) {
            // setnamecalss("")
            getDepartment()
            handleColse()
            console.log("Edit")
          }
        })
      }
  
    const addNameClass = async() => {
        const response = await axios.post(GET_CLASS_URL, JSON.stringify({ 'des': nameclass }), {
          headers: { 'Content-Type': 'application/json' }
        })
        console.log(response)
        if (response?.status == 201) {
          // setnamecalss("")
          getDepartment()
          handleColse()
        }
        else {
          console.log("error add class")
        }
      }

    return (
        <Modall showModal={showModal}>
            <div className=' w-full divide-y-2  divide-slate-500'>
                <div className='w-full bg-[#0d406b] flex justify-end'>
                    <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={() => {
                        handleColse()
                    }} >x</button>
                </div>
                {/* <div className='w-full p-5 flex flex-col justify-end '> */}
                    {/* {children} */}
                    {/* <div className='w-full  pb-0 p-3 flex justify-end '>

                        <input type="text" id="class" className='border w-[70%] text-right' value={nameclass} onChange={(e) => {handleCheck(e.target.value) }} />
                        <label className='flex px-4' htmlFor="class"><p className='text-red-600'>*</p>שם המחלקה</label>

                    </div>
                    {error&&<p className='text-red-500 text-center'>{error}</p>}

                </div> */}
<div className='w-full p-5 flex flex-col justify-end ' style={{direction:"rtl"}}>
                <InputUniversal label="שם המחלקה" id="class"  
                            req={true}   
                            className='grid col-span-5  border'
                                type="text"
                                
                                name="class"
                                error={error}
                                value={nameclass}
                                // value={formData.lastName}
                                onChange={handleCheck}
                            />
                 </div>      
                <div className='w-full p-2   flex justify-end'>
                    <button className='border bg-green-500 hover:bg-green-300 p-1 px-3' onClick={() => {
                        if (nameclass != "") {
                            row?handleEditClass(row.id):addNameClass()
                        }
                        else
                        (setError("שם המחלקה ריק"))
                        

                    }}>שמור</button>
                    <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3' onClick={() => {
                        // reset()
                        // setShowModal(false)
                        handleColse()
                    }}>בטל</button>
                </div>
            </div>

        </Modall>
    )
}

export default ModalNewClass