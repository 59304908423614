import './App.css';
import Login from './pages/Login';
import Main from './pages/Main'
import Members from './pages/Members'
import Tranings from './pages/Tranings'
import Adminstration from './pages/Adminstration';
import {
  createBrowserRouter,
  RouterProvider,useNavigate
} from "react-router-dom";
import HomePage from './pages/HomePage';
import Signup from './pages/Signup';
import ErrorPage from './pages/ErrorPage';
import RegistrationTrainingDays from './pages/RegistrationTrainingDays';
import { useEffect, useState } from 'react';
import ProtectedRoute from './Security/ProtectedRoute';
import { useAuth } from './Context/AuthProvider';
import Home from './App/Pages/Home';
import Signatures from './App/Pages/Signatures';
import Mobile from './App/Pages/Mobile';
import { redirect } from 'react-router-dom';
import MyCourses from './App/Pages/MyCourses';

function App() {

  const { auth, logout, setAuth } = useAuth()
  const [hide,setHide]=useState("")



  // const NavigateBasedOnDevice = () => {
  //   const userAgent = navigator.userAgent;
  //   if (/iPhone|iPad|iPod|Mobi|Android/i.test(userAgent)) {
  //     console.log("sd")
  //     return redirect('/Mobile');
  //   }
  //   console.log("sds")
  //   return redirect('/');
  // };



  // const NavigateBasedOnDevice = () => {
  //   const navigate = useNavigate();  // useNavigate hook is called here inside a component wrapped by Router
  //   // useEffect(() => {
  //     const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  //     if (isMobile) {
  //       console.log("moblie")
  //        navigate('/Mobile');
  //     } else {
  //       console.log("cp")
  //       navigate('/');
        
  //     }
  //   // }, [navigate]);
  // }


  const router = createBrowserRouter([
    {
      path: "",
      element: <Main />,
      errorElement: <ErrorPage/>,
      children: [


        {
          path: "",
          
          element:<ProtectedRoute isAuthenticated={auth}> <HomePage /></ProtectedRoute>,
        },
        {
          path: "members",
          element: <ProtectedRoute isAuthenticated={auth}> <Members setHide={setHide}/></ProtectedRoute>,
        },
        {
          path: "tranings",
          element: <ProtectedRoute isAuthenticated={auth}> <Tranings setHide={setHide} /></ProtectedRoute>,
        },
        {
          path: "adminstration",
          element: <ProtectedRoute isAuthenticated={auth}> <Adminstration setHide={setHide}/></ProtectedRoute>
        },
        
        {
          path: "registrationTrainingDays/:corseId",
          element: <RegistrationTrainingDays/>
        },
        {
          path: "registrationTrainingDays",
          element: <RegistrationTrainingDays/>
        },

        

        // {
        //   path: "dashboard",
        //   element: <Dashboard />,
        //   loader: ({ request }) =>
        //     fetch("/api/dashboard.json", {
        //       signal: request.signal,
        //     }),
        // },
        // {
        //   element: <AuthLayout />,
        //   children: [
        //     {
        //       path: "login",
        //       element: <Login />,
        //       loader: redirectIfUser,
        //     },
        //     {
        //       path: "logout",
        //       action: logoutUser,
        //     },
        //   ],
        // },
      ],
    },
    {
      path: "/login",
      element: <Login/>
    },
    {
      path: "/Signup",
      element: <Signup/>
    }
    ,{
      path: "/Mobile",
      element: <Mobile/>,
      children: [
        {
          path: "",
          
          element:<ProtectedRoute isAuthenticated={auth}> <Home /></ProtectedRoute>,
        },
        {
          path: ":corseId",
          
          element:<ProtectedRoute isAuthenticated={auth}> <Home /></ProtectedRoute>,
        },
        {
          path: "Signatures",
          element: <ProtectedRoute isAuthenticated={auth}> <Signatures setHide={setHide}/></ProtectedRoute>,
        },
        {
          path: "MyCourses",
          element: <ProtectedRoute isAuthenticated={auth}> <MyCourses/></ProtectedRoute>,
        },
        // {
        //   path: "tranings",
        //   element: <ProtectedRoute isAuthenticated={auth}> <Tranings setHide={setHide} /></ProtectedRoute>,
        // },]
      ]
    }
  
  ]);

   
 


  return (
    // <div className={`App font-sans text-xs relative h-screen  ${hide!=""?"overflow-hidden":""}`}>
    <div className={`App font-sans text-xs relative h-screen`}>
     
      <RouterProvider router={router} >
      </RouterProvider>
    </div>
  );
}

export default App;
// div {
//   margin-bottom: 10px;
//   position: relative;
// }

// input[type="number"] {
//   width: 100px;
// }

// input + span {
//   padding-right: 30px;
// }

// input:invalid + span::after {
//   position: absolute;
//   content: "✖";
//   padding-left: 5px;
// }

// input:valid + span::after {
//   position: absolute;
//   content: "✓";
//   padding-left: 5px;
// }
