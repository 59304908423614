import React, { useEffect, useState } from 'react'
// import { HeaderTable } from '../../HeaderTable'
// import DataTable from 'react-data-table-component'
import axios from 'axios'
import TimeInput from '../../../ui/TimeInput'
import { convertToIsraelTime, formatOnlyDate } from '../../../../util/formatsForDate'

import { PiCertificateLight } from "react-icons/pi";




const TrainingDaysDetails = ({ row }) => {
    const [getDetails,setDetDetails]=useState([])
    const GET_URL = `admin/qualification/get_qualification_details/${row?.id}`
   
    const URL_EXCLE = `admin/qualification/get_sample_certificate/${row?.id}`
    const [loading, setloading] = useState(false)
   
    useEffect(()=>{
        const funcdata =async()=> await axios.get(GET_URL, {
        }).then(response => {
            if (response.status == 200) {
                console.log(response.data)
               setDetDetails (response.data)
            }
        })
        funcdata()
        
    },[])
  
    const downloadExcel = async () => {
        try {
          const response = await axios.get(URL_EXCLE, {
           
            responseType: 'blob', // הגדרת התגובה כ-blob לצורך הורדת הקובץ
          });
      
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'report.pdf');
          document.body.appendChild(link);
          link.click();
          link.remove();
        } catch (error) {
          console.error('Error downloading file:', error);
        }
      
        }

    return (
        <div className='grid gap-y-3 text-right bg-gray-50 p-6 rounded-md shadow-md' dir='rtl'>

<button onClick={async()=>{
        setloading(true)
        
        await downloadExcel()
        setloading(false)}} 
        disabled={loading} className={`m-2 mr-0 ${loading?"bg-slate-400":"bg-green-500 hover:bg-green-300"} text-white w-fit mx-2 p-2 rounded`}>
            {
                  loading ? (
                          // אייקון טעינה - ספירלה מסתובבת
                          <span className="flex items-center">
                            <svg className="animate-spin h-5 w-5 text-white mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                            </svg>
                            ... מוריד
                          </span>
                        ) : (
                          // הטקסט של הכפתור במצב הרגיל
                          <span className="flex items-center gap-2 py-1"> הפקת תעודה <PiCertificateLight/></span>
                        )}
                
                
                </button>
            <div className='grid grid-cols-10 items-center' >
                <div className='col-span-3  font-bold text-gray-700' >מספר אשכול</div>
                <div className='col-span-7 text-gray-900'>{getDetails?.clusterNumber} </div>
            </div>
            <div className='grid grid-cols-10 items-center' >
                <div className='col-span-3 font-bold text-gray-700'>שם אשכול</div>
                <div className='col-span-7 text-gray-900'>{getDetails?.clusterName} </div>
            </div>
            <div className='grid grid-cols-10 items-center' >
            <div className='col-span-3 font-bold text-gray-700' >מספר יום כשירות</div>
                <div className='col-span-7 text-gray-900'>{getDetails?.qualificationNumber} </div>
            </div>
            <div className='grid grid-cols-10 items-center' >
            <div className='col-span-3 font-bold text-gray-700' >שם יום הכשירות</div>
                <div className='col-span-7 text-gray-900'>{getDetails?.qualificationName} </div>
            </div>
            <div className='grid grid-cols-10 items-center' >
            <div className='col-span-3 font-bold text-gray-700' >תיאור</div>
                <div className='col-span-7 text-gray-900' >{getDetails?.description} </div>
            </div>
            <div className='grid grid-cols-10 items-center' >
            <div className='col-span-3 font-bold text-gray-700' >תאריך התחלה</div>
                <div className='col-span-7 text-gray-900' >{getDetails?.startDate?convertToIsraelTime(getDetails?.startDate):""} </div>
            </div>
            <div className='grid grid-cols-10 items-center' >
            <div className='col-span-3 font-bold text-gray-700' >מספר משתתפים מקסימלי</div>
                <div className='col-span-7 text-gray-900'  >{getDetails?.maximumParticipants} </div>
            </div>
            <div className='grid grid-cols-10 items-center' >
            <div className='col-span-3 font-bold text-gray-700' >מספר משתתפים רשומים</div>
                <div className='col-span-7 text-gray-900' >{getDetails?.registerParticipants} </div>
            </div>
            <div className='grid grid-cols-10 items-center' >
            <div className='col-span-3 font-bold text-gray-700' >מיקום</div>
                <div className='col-span-7 text-gray-900' >{getDetails?.place} </div>
            </div>
            <div className='grid grid-cols-10 items-center' >
            <div className='col-span-3 font-bold text-gray-700' >Latitude</div>
                <div className='col-span-7 text-gray-900' >{getDetails?.latitude} </div>
            </div>
            <div className='grid grid-cols-10 items-center' >
            <div className='col-span-3 font-bold text-gray-700' >Longitude</div>
                <div className='col-span-7 text-gray-900' >{getDetails?.longitude} </div>
            </div>
            <div className='grid grid-cols-10 items-center' >
                <div className='col-span-3 font-bold text-gray-700' >סטטוס</div>
                <div className='col-span-7 text-gray-900' >{getDetails?.statusQualificationDesc} </div>
            </div>
        </div>
    )
}

export default TrainingDaysDetails





  // getDetails.then(data => {
    //     console.log(data)})
    //delete const getDataTable = async (page) => {
    //     setloading(true)
    //     await axios.get(GET_URL, {
    //     //    
    //     })
    //         .then(response => {
    //             if (response.status == 200) {
    //                 setFetchData(response?.data)
    //                 // setdataForfilterScrach(response?.data)
    //                 // setTotalRows(response.data.totalElements);
    //             }
    //         })
    //     setloading(false)

    // }