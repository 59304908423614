import React, { useState,useEffect } from 'react'
import Fillter from '../../../../Fillter'
import Select from "react-select"

const RegistersMemberQualificationFilter = ({ setDataFillterout,datafiler}) => {
   
     
     
    const [dataFillter, setDataFillter] = useState({
       "member_number": ""
    })
    const restart = async () => {
        setDataFillter(
            {
               "member_number": ""
            }
        )
        setDataFillterout({ "member_number": "" })
        // await getPaying_customersFilter()
    }

    const handleChangeDate = (e) => {
        const { name, value } = e.target;
        setDataFillter({
            ...dataFillter,
            [name]: value
        });
    }




    const handleChange = (selectedOption, name,) => {
        console.log(selectedOption)
        // const { name } = e.name;
        setDataFillter({
            ...dataFillter,
            [name]: selectedOption?.value
            ,
        })



    }  
   
  
    const actionFilter = () => {
        setDataFillterout(dataFillter)
    }

    
    return (
        <Fillter actionFilter={actionFilter} restart={restart}>
            <div className='flex justify-end items-center'>
                <Select className='border border-black w-2/3 text-right '
                    type="text"
                    id="member_number"
                    name="member_number"
                    value={dataFillter?.member_number ?
                        { value: dataFillter.member_number, label: dataFillter.member_number } : null}
                    onChange={(e) => handleChange(e, 'member_number')}
                    options={datafiler?.map((item) => { return { value: item, label: item} })}
                    placeholder="...חפש"
                    isClearable
                    isSearchable

                    maxMenuHeight={100}
                />


                <label className='ml-3 w-1/6' htmlFor="member_number">מספר חבר</label>
            </div>
        </Fillter>
    )
}

export default RegistersMemberQualificationFilter