import React, { useEffect, useState, useRef } from 'react';
import './TimeInput.css';

const TimeInput = ({ labal, id, name, handleChange1, value }) => {
    // Initialize the state for hours, minutes, and seconds
    const [hours, setHours] = useState(value ? Number(value.split(":")[0]) : 0);
    const [minutes, setMinutes] = useState(value ? Number(value.split(":")[1]) : 0);
    const [seconds, setSeconds] = useState(value ? Number(value.split(":")[2]) : 0);
    const containerRef = useRef(null);
    const [open, setOpen] = useState(false)
    // const [time, setTime] = useState(null)
    // const [formattedTime, setFormattedTime] = useState("");
    // Utility functions to handle increment/decrement
    const increment = (type) => {

        if (type === 'hours') {
            setHours((prev) => (prev + 1) % 24); // Wrap around to 0 after 23
        } else if (type === 'minutes') {
            setMinutes((prev) => (prev + 15) % 60); // Wrap around to 0 after 59
        } else if (type === 'seconds') {
            setSeconds((prev) => (prev + 1) % 60); // Wrap around to 0 after 59
        }

    };



    const decrement = (type) => {
        if (type === 'hours') {
            setHours((prev) => (prev === 0 ? 23 : prev - 1)); // Wrap around to 23 from 0
        } else if (type === 'minutes') {
            setMinutes((prev) => (prev - 15 < 0 ? 0 : prev - 15)); // Wrap around to 59 from 0
        } else if (type === 'seconds') {
            setSeconds((prev) => (prev === 0 ? 59 : prev - 1)); // Wrap around to 59 from 0
        }
        // setTime(Date(hours, minutes, seconds))
    };
    useEffect(() => {
        //     // const data = new Date()
        //     // data.setHours(hours)
        //     // data.setMinutes(minutes)
        //     // data.setSeconds(seconds)
        //     // console.log(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`)
        handleChange1(name, `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`)
        //     // setFormattedTime(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`)
        //     // setTime(data)
    }, [hours, minutes, seconds])
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                console.log(name)
                //  handleChange1(name ,`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`)

                // containerRef.current.blur();
                setOpen(false); // Close the component
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <div className='grid grid-cols-10 ' dir='rtl'>
            <label htmlFor={id} className='col-span-3'>{labal}</label>
            <div className='col-span-4 text-right relative' >
                <input
                    className='w-52'
                    id={id}
                    name={name}
                    value={`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`}
                    onClick={() => setOpen(true)}
                />
                {open && <div ref={containerRef} className="time-input-container absolute top-full z-10" >
                    {/* Seconds */}
                    <div className="time-input-box">
                        <button className="arrow-button" type='button' onClick={() => increment('seconds')}>
                            ▲
                        </button>
                        <input
                            type="text"
                            placeholder='SS'
                            value={String(seconds).padStart(2, '0')}
                            onChange={(e) => setSeconds(Math.min(59, Math.max(0, Number(e.target.value))))}
                            className="time-input-field"
                        />
                        <button className="arrow-button" type='button' onClick={() => decrement('seconds')}>
                            ▼
                        </button>
                    </div>

                    <span>:</span>

                    {/* Minutes */}
                    <div className="time-input-box">
                        <button className="arrow-button" type='button' onClick={() => increment('minutes')}>
                            ▲
                        </button>
                        <input
                            type="text"
                            placeholder='mm'
                            disabled={true}
                            value={String(minutes).padStart(2, '0')}
                            onChange={(e) => setMinutes(Math.min(59, Math.max(0, Number(e.target.value))))}
                            className="time-input-field"
                        />
                        <button className="arrow-button" type='button' onClick={() => decrement('minutes')}>
                            ▼
                        </button>
                    </div>

                    <span>:</span>
                    <div className="time-input-box">
                        <button className="arrow-button" type='button' onClick={() => increment('hours')}>
                            ▲
                        </button>
                        <input
                            type="text"
                            placeholder='HH'

                            value={String(hours).padStart(2, '0')}
                            onChange={(e) => setHours(Math.min(23, Math.max(0, Number(e.target.value))))}
                            className="time-input-field"
                        />
                        <button className="arrow-button" type='button' onClick={() => decrement('hours')}>
                            ▼
                        </button>
                    </div>

                </div>}
            </div>
        </div>
    );
};

export default TimeInput



// const [hours, setHours] = useState(0);
// const [minutes, setMinutes] = useState(0);
// const [seconds, setSeconds] = useState(0);

// // Utility functions to handle increment/decrement
// const increment = (type) => {
//   if (type === 'hours') {
//     setHours((prev) => (prev + 1) % 24); // Wrap around to 0 after 23
//   } else if (type === 'minutes') {
//     setMinutes((prev) => (prev + 1) % 60); // Wrap around to 0 after 59
//   } else if (type === 'seconds') {
//     setSeconds((prev) => (prev + 1) % 60); // Wrap around to 0 after 59
//   }
// };

// const decrement = (type) => {
//   if (type === 'hours') {
//     setHours((prev) => (prev === 0 ? 23 : prev - 1)); // Wrap around to 23 from 0
//   } else if (type === 'minutes') {
//     setMinutes((prev) => (prev === 0 ? 59 : prev - 1)); // Wrap around to 59 from 0
//   } else if (type === 'seconds') {
//     setSeconds((prev) => (prev === 0 ? 59 : prev - 1)); // Wrap around to 59 from 0
//   }
// };

// return (
//   <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//     {/* Hours input */}
//     <div>
//       <button onClick={() => increment('hours')}>▲</button>
//       <div style={{ textAlign: 'center', width: '40px' }}>
//         {String(hours).padStart(2, '0')}
//       </div>
//       <button onClick={() => decrement('hours')}>▼</button>
//     </div>

//     <span>:</span>

//     {/* Minutes input */}
//     <div>
//       <button onClick={() => increment('minutes')}>▲</button>
//       <div style={{ textAlign: 'center', width: '40px' }}>
//         {String(minutes).padStart(2, '0')}
//       </div>
//       <button onClick={() => decrement('minutes')}>▼</button>
//     </div>

//     <span>:</span>

//     {/* Seconds input */}
//     <div>
//       <button onClick={() => increment('seconds')}>▲</button>
//       <div style={{ textAlign: 'center', width: '40px' }}>
//         {String(seconds).padStart(2, '0')}
//       </div>
//       <button onClick={() => decrement('seconds')}>▼</button>
//     </div>
//   </div>
// );
// };
