import React, { useState } from 'react'
// import Select from "react-select"
import NewAddMember from './NewAddMember'
import axios from 'axios'
const PopAddPayingMebers = ({ handleColse,row,getDataTable }) => {
    const GET_URL = `admin/setting/paying_customer/add/${row?.id}/groupMember`
    const [selectedItems, setSelectedItems] = useState([]);
    
    
    const handleAddMember = async () => {
        const selectedItems1=  selectedItems?.map((option) =>option?.id)
        // console.log(selectedItems1)
        // await axios.post(`${GET_URL}/${row.id}`,[1073],{
        await axios.post(GET_URL,selectedItems1,{
            headers: { 'Content-Type': 'application/json' }}).then(response => {
            if (response.status == 200) {
                // handleColse()
                // getPaying_customers(currentPage-1)
                // getFilter()
                getDataTable()
                console.log("ok")
            }
            else {
                console.log("error")
            }
        })
    }
    return (

        <div className=' fixed top-0 bottom-0 right-0 left-0 flex flex-col items-center py-6 w-full min-h-screen drop-shadow-2xl shadow-2xl bg-slate-600/80 overflow-y-auto'>
            <div className='bg-white w-[60%] rounded-lg divide-y-2  divide-slate-500 border-0 border-emerald-50 '>
                <div className='w-full bg-[#0d406b] rounded-t-md flex justify-end border-[#0d406b] border-2'>
                    <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={() => {
                        handleColse()
                    }} >x</button>
                </div>
                <div className='flex items-end w-full p-2'>
                   
                    <NewAddMember selectedItems={selectedItems} setSelectedItems={setSelectedItems} row={row}/>
                </div>
                <div className='w-full flex justify-center'>
                <div className='w-[80%] px-20 py-1 flex justify-between '>
                    <button className='border bg-green-600 hover:bg-green-500 p-1 px-3 mb-2' onClick={() => {
                        // reset()
                        // setShowModal(false)
                        handleAddMember()
                        handleColse()
                    }}>צרף</button>
                    <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3 mb-2' onClick={() => {
                        // reset()
                        // setShowModal(false)
                        handleColse()
                    }}>סגור</button>
                </div>
                </div>
            </div>
        </div>

    )
}

export default PopAddPayingMebers