import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import { HeaderTable } from '../../HeaderTable'
import { BsPencil } from "react-icons/bs";
import { IoTrashOutline } from "react-icons/io5";
import ModalEdit from '../../ModalEdit';
import ButtonNew from '../../ButtonNew';
import NewActivityday from '../../tranings/trainingdays/subTab_more_options/Activityday/NewActivityday';
import NewCluster from './NewCluster';

const Cluster = () => {
    const [modalIsOpen, setModalIsOpen] = useState("");
    const [id, setId] = useState("");
    const [fetchData, setFetchData] = useState([])
    const [loading, setloading] = useState(true)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const GET_URL = 'admin/setting/cluster'

    const getForms = async (page) => {
        // setloading(true);
        // await axios.get(`${GET_PAYING_CUSTOMERS_URL}s`).then(response => {
        await axios.get(GET_URL, {
            params: {
                "page": page
                , "size": perPage,
            }
        })
            .then(response => {
                setFetchData(response?.data?.clusters)
                setTotalRows(response?.data?.totalElements);
            })
        setloading(false)
        console.log(fetchData)
    }


    useEffect(() => {
        getForms(currentPage - 1)
    }, [currentPage, perPage])

    const handlePerRowsChange = async (newPerPage) => {
        setPerPage(newPerPage);

    }



    const handlePageChange = (page) => {

        setCurrentPage(page);
    };

    const handleColse = () => {
        setModalIsOpen("")
    }

    const handleDetele = async () => {

        await axios.delete(`${GET_URL}/${id.id}`).then(response => {
            if (response.status == 200) {
                handleColse()
                getForms()
                console.log("delete")
            }
        })
    }


    const columns = [

        {
            name: "פעולות",

            cell: row => <div><button className=' border border-gray-500 p-2 opacity-15 hover:opacity-100' onClick={() => {
                // setnamecalss(row?.description)
                // setAction("edit")
                setId(row)
                setModalIsOpen("Edit")
            }
            }><BsPencil /></button>
                <button title="מחיקה" className=' border border-gray-500 ml-2 p-2 opacity-15 hover:opacity-100' onClick={() => {
                    setId(row)
                    setModalIsOpen("Delete")
                }}><IoTrashOutline /></button> </div>,

            style: {
                fontSize: '10px',
                justifyContent: 'center',

            },
            grow: 1,
        },
        ,
        {
            name: "שם האשכול",
            grow: 4,
            selector: (row) => row.name,
            style: {
                fontSize: '12px',
                justifyContent: 'center',

            },
            // sortable: true,
        },
        {
            name: "מספר אשכול",
            grow: 2,
            selector: (row) => row.number,
            style: {
                fontSize: '12px',
                justifyContent: 'center',
                width: '100px'
            },
            // sortable: true,
        }


    ]



    const customStyles = {
        header: {
            style: {

                justifyContent: 'center',

            },
        },
        id: {
            style: {
                '&:2': { backgroundColor: '#EEEEEE' },
            }
        },
        headRow: {
            style: {
                justifyContent: 'center',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                // borderTopColor: defaultThemes.default.divider.default,
            },
        },
        headCells: {
            style: {

                justifyContent: 'center',
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                // borderRightColor: defaultThemes.default.divider.default,

            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {

                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    // borderRightColor: defaultThemes.default.divider.default,
                },
            },
        },
        rows: {
            style: {
                backgroundColor: '#FFFFFF',
                '&:active': {
                    backgroundColor: '#EEEEEE',
                },
            },
        },
    }


    return (
        <div className='w-full '>

            <HeaderTable active={"סטטוסים לימי כשירות"} setModalIsOpen={setModalIsOpen} ></HeaderTable>
            <DataTable columns={columns} data={fetchData} pagination customStyles={customStyles}
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressPending={loading} className='border' >
            </DataTable>
            {modalIsOpen == "New" && <NewCluster showModal={modalIsOpen == "New"} GET_URL={GET_URL} handleColse={handleColse} getData={getForms} />}
            {modalIsOpen == "Edit" && <NewCluster showModal={modalIsOpen == "Edit"} GET_URL={GET_URL} handleColse={handleColse} getData={getForms} row={id} />}
            {modalIsOpen == "Delete" && <ModalEdit showModal={modalIsOpen == "Delete"} handleColse={handleColse} handleDetele={handleDetele}></ModalEdit>}

        </div>

    )
}

export default Cluster