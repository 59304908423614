import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Modall from '../../Modall'
import Input from '../../ui/Input'

const GET_STATUS_MEMBER_URL='admin/setting/status_member'
const ModleNewStatus_Member = ({ showModal, handleColse,getDepartment,row="" }) => {
             
    // const [enteredValues, setEnteredValues] = useState({
    //     email: '',
    //     password: '',
    //   });
    
    //   const [didEdit, setDidEdit] = useState({
    //     email: false,
    //     password: false,
    //   });
    
    //   const emailIsInvalid = didEdit.email && !enteredValues.email.includes('@');
    //   const passwordIsInvalid =
    //     didEdit.password && enteredValues.password.trim().length < 6;
    
    //   function handleSubmit(event) {
    //     event.preventDefault();
    
    //     console.log(enteredValues);
    //   }
    
    //   function handleInputChange(identifier, value) {
    //     setEnteredValues((prevValues) => ({
    //       ...prevValues,
    //       [identifier]: value,
    //     }));
    //     setDidEdit((prevEdit) => ({
    //       ...prevEdit,
    //       [identifier]: false,
    //     }));
    //   }
    
    //   function handleInputBlur(identifier) {
    //     setDidEdit((prevEdit) => ({
    //       ...prevEdit,
    //       [identifier]: true,
    //     }));
    //   }
    
    
    
    
    
    
    
    // *****
    
    
    const [formData, setFormData] = useState(row?{"des":row?.statusDescription}:{des:""});
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            [name]: value
        });
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.des) newErrors.name = 'שדה חובה'
        return newErrors;
    };


    const handleSubmit = () => {
        // e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            // Submit the form data
            console.log(formData)
            addNewPaying()
           
        }
    };



    
    const handleEditClass = async (id) => {
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
        await axios.put(`${GET_STATUS_MEMBER_URL}/${id}`, JSON.stringify({ 
        "des": formData.des,
     }), {
            headers: { 'Content-Type': 'application/json' }
        }).then(response => {
            if (response.status == 200) {
                // setnamecalss("")
                getDepartment()
                handleColse()
                console.log("Edit")
            }
        })
    }}

    const addNewPaying = async () => {
        const response = await axios.post(GET_STATUS_MEMBER_URL, JSON.stringify({
            "des": formData.des,
            
        }), {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log(response)
        if (response?.status == 200) {
            // setnamecalss("")
            getDepartment()
            handleColse()
        }
        else {
            console.log("error add class")
        }
    }
    return (
        <Modall showModal={showModal}>
        <div className=' w-full divide-y-2  divide-slate-500'>
            <div className='w-full bg-[#0d406b] flex justify-end'>
                <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={() => {
                    handleColse()
                }} >x</button>
            </div>
      
            {/* <form className='w-full p-5 flex flex-col  text-center text-sm'> */}
            {/* <form className='w-full p-5 grid grid-cols-10 text-sm'> */}
            <form className='max-md:w-[600px] m-[30px auto]  p-2 flex flex-col  ' style={{ direction: "rtl" }} >
           {/* ************************* */}
               
                    <Input
                     className='grid col-span-5  border'
                    label="סטטוט"      
                    req={true}
                    type="text"
                        id="des"
                        name="des"
                        value={formData?.des}
                        onChange={handleChange}
                        error={errors.name}
                    />
                   


                
            </form>
            <div className='w-full p-2   flex justify-end'>
                <button className='border bg-green-500 hover:bg-green-300 p-1 px-3' onClick={() => {
                     {
                        row ? handleEditClass(row.statusId) : handleSubmit()
                    }
                    // handleSubmit()


                }}>שמור</button>
                <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3' onClick={() => {
                    // reset()
                    // setShowModal(false)
                    handleColse()
                }}>בטל</button>
            </div>
        </div>

    </Modall>
  )
}

export default ModleNewStatus_Member