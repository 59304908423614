// export function isEmail(value) {
//   // return value.includes('@');
//   return (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/.test(value));
// }


export function validateIsraeliID(id) {
  // אם מספר הספרות קטן מ-9, נבצע padding של אפסים משמאל
  id = id.toString().padStart(9, '0');

  // בדיקת אורך התעודה
  if (id.length !== 9) return false;

  let sum = 0;

  for (let i = 0; i < 9; i++) {
    let digit = parseInt(id[i], 10); // המרה למספר
    digit *= i % 2 === 0 ? 1 : 2;    // אם המיקום זוגי - נשאר אותו מספר, אם לא - מכפילים ב-2
    if (digit > 9) digit -= 9;       // אם התוצאה גדולה מ-9, נבצע חיסור של 9

    sum += digit; // חיבור התוצאה לסכום
  }
  return sum % 10 === 0; // המספר תקין אם הסכום מתחלק ב-10
}





export function isEmailValid (value) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
  return emailPattern.test(value);
};

// input type="text" pattern="[0-9]*"


export function isNotEmpty(value) {
  return value.trim() !== '';
}

export function hasMinLength(value, minLength) {
  return value.length >= minLength;
}

export function isEqualsToOtherValue(value, otherValue) {
  return value === otherValue;
}