import React from 'react'

const InputUniversal = ({ label, id, error ,className,req=false,onChange, ...props }) =>  {
    return (
      <div className="w-full ">
        <div className={error?'text-red-500  grid grid-cols-10 my-1':'grid grid-cols-10 my-1'}>
            <label className="col-span-3 flex justify-center" htmlFor={id}>
                  {label}
                  {req && <span className="text-red-600 ml-1">*</span>}
            </label>
             <input  id={id} className={error?' border-red-500'+className:className} onChange={(e) => {onChange(e) }}{...props} />
        </div>
        < div className="text-red-500 text-center">{error && <p>{error}</p>}</div>
      </div>)
}

export default InputUniversal