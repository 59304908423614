import React, { useEffect, useState } from 'react'
import CourseStatusCard from '../copmponents/CourseStatusCard';
import axios from 'axios';

const MyCourses = () => {
 const[courses,setCourses]=useState([])
 const GET_URL = 'dashboard/member/my_qualification_day'
 const getData = async () => {

    await axios.get(GET_URL, {
    })
        .then(response => {
            if (response.status == 200) {
        setCourses(response?.data)
        }})
}
useEffect(()=>{
  getData()

},[])
    // const courses = [
    //     { courseName: "רשימת המתנה לפוליסה", date: "2099-01-01", status: "באישור" },
    //     { courseName: "בטיחות בעבודה עם כבישים", date: "2023-12-19", status: "מבוצע" },
    //     { courseName: "ניסיון בגרור - תקלה", date: "2022-01-25", status: "ביטול" },
    //   ];
    
      return (
        <div className='w-full h-[80%] grid grid-cols-1 my-2 overflow-y-auto pb-10'>
          {courses.map((course, index) => (
            <CourseStatusCard
              key={index}
              courseName={course?.mySelfQualificationDto?.name}
              date={course?.date}
              status={course?.mySelfQualificationDto?.statusQualification?.statusDescription}
            />
          ))}
        </div>
      );
}

export default MyCourses