import axios from "axios";
import Cookies from "universal-cookie";
import { useAuth } from "../Context/AuthProvider";
import { useNavigate } from "react-router-dom";
//NOAM 
axios.defaults.baseURL = 'https://backapi.ismbonline.org/api/';




//MATAN
// axios.defaults.baseURL = 'http://ec2-35-180-160-9.eu-west-3.compute.amazonaws.com/api/';


let refresh = false;
const cookies = new Cookies()

axios.interceptors.request.use(
    (config) => {
        if (config.url === 'auth/refreshtoken') {
            // אם ה-URL תואם, מסירים את ה-Authorization header
            delete config.headers['Authorization'];
        } else {
            // אם לא, מוסיפים את ה-Authorization header עם ה-JWT מתוך localStorage
            const token = localStorage.getItem('access_token'); // שליפה של ה- JWT מתוך localStorage
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`; // אם יש טוקן, נוסיף אותו ל-Headers של הבקשה
            }
        }
        return config; // החזרת ה-config עם ה-Authorization
    },
    (error) => {
        return Promise.reject(error); // אם יש שגיאה, מחזירים את השגיאה
    }
);

axios.interceptors.response.use(
    resp =>
    { 
        refresh = false
        console.log("Good");
        return resp
    } ,
    async error => {
        console.log("Error")
        if (error.response.status === 401 && !refresh) {
            refresh = true;
            axios.defaults.headers.common['Authorization'] = null;
            //const refershToken = cookie.get('bezkoder-jwt-refresh'); 
            // const responses = await axios.post('auth/refreshtoken' , {"token":refershToken});
            const responses = await axios.post('auth/refreshtoken' , {"token":"123"} , { withCredentials: true });
            // const responses = await axios.get('refreshtoken',  { withCredentials: true });

            if (responses.status === 200) {

                const newAccessToken = responses.data['access_token'];
                // שמירת ה-access_token החדש ב-localStorage
                localStorage.setItem('access_token', newAccessToken);
                axios.defaults.headers.common['Authorization'] = `Bearer ${responses.data['access_token']}`;
                error.config.headers['Authorization'] = `Bearer ${responses.data['access_token']}`
                return axios(error.config);
            }
            else
            {
                cookies.remove('role')
                cookies.remove('isAllow')
                cookies.remove('refresh-token')
                localStorage.removeItem('access_token'); 
                axios.defaults.headers.common['Authorization'] = null;
            
                // cookie.remove('refresh-token')
                // navigate("/login")
                window.location.href = '/login';
                refresh = true;
            }
        }else if (error.response.status === 403) {
            // אם השגיאה היא 403, נשלח את המשתמש לדף התחברות
            cookies.remove('role')
            cookies.remove('isAllow')
            cookies.remove('refresh-token')
            localStorage.removeItem('access_token'); 
            axios.defaults.headers.common['Authorization'] = null;
        
            // cookie.remove('refresh-token')
            // navigate("/login")
            window.location.href = '/login';
            refresh = true;
        }
        
        refresh = false;
        console.log(error)
        return error;
    });