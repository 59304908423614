import React from 'react'
import "./RegistrationCourse.css"; // עיצוב חיצוני
import { formatActivityDay,convertToIsraelTime } from '../../util/formatsForDate';

const RegistrationCourse = ({ onClose, course, onNext }) => {
  
  return (
    <div className="popup-overlay ">
      <div className="popup-content">
        <button className="close-btn" onClick={onClose}>
          ✕
        </button>

        <img
          src="https://images.pexels.com/photos/4391470/pexels-photo-4391470.jpeg"
          alt="תמונה"
          className="popup-image"
        />

        <h3 className="popup-title">
          {/* רשימת מתנה לפוליסת האחריות המקצועית של לשכת ממוני הבטיחות */}
          {course?.mySelfQualificationDto?.name}
        </h3>
{/* 
        <p className="popup-description">
          ביום עיון נלמד, על אילו תחומים חלה פוליסת הבטיחות בעבודה:
          <br />
          • חידוש חוק, תקנות ונהלים רלוונטיים לממונה בטיחות <br />
          • ניהול סיכונים ודרכי מניעה <br />
          • דגשים חשובים בניהול מערכות בטיחות <br />
          • ועוד...
        </p> */}
        <p className="popup-description">
          {course?.mySelfQualificationDto?.description}
        
        </p>

        <p className="popup-details">
          {course?.mySelfQualificationDto?.activityDays?.length>0 && <><b>מתי המפגש:</b>
          <br />
            {course?.mySelfQualificationDto?.activityDays.map((activityDay,index) => { return formatActivityDay(activityDay) })}
            <br />
          </>
          }
          {/* יום רביעי 15:00-20:00 <br />
          התכנסות: 10:00 <br /> */}
         
          <b>מיקום:</b> {course?.mySelfQualificationDto?.place} <br />
          <b>עלות:</b> {course?.product?.price} ₪<br />
          <b> תאריך הכשרה:</b> {convertToIsraelTime(course?.mySelfQualificationDto?.startDate)} <br />
          <b>מספר מפגשים:</b>{course?.daysWork==1? " 1 מפגש":course?.daysWork==0? " -": ` ${course?.daysWork} מפגשים`}
        </p>

        <button className="register-btn" onClick={onNext} disabled={course?.isRegistered}>{course?.isRegistered?"הינך רשומ/ה":"מעבר להרשמה"}</button>
      </div>
    </div>
  )
}

export default RegistrationCourse