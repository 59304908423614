import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Modall from '../Modall'
import Input from '../ui/Input'
const GET_PRODUCT_URL='admin/setting/product'

const ModalNewProducts = ({ showModal, handleColse,getDepartment,row="" }) => {
    
    // ******
    
    
               
    const [enteredValues, setEnteredValues] = useState({
        email: '',
        password: '',
      });
    
      const [didEdit, setDidEdit] = useState({
        email: false,
        password: false,
      });
    
      const emailIsInvalid = didEdit.email && !enteredValues.email.includes('@');
      const passwordIsInvalid =
        didEdit.password && enteredValues.password.trim().length < 6;
    
    //   function handleSubmit(event) {
    //     event.preventDefault();
    
    //     console.log(enteredValues);
    //   }
    
      function handleInputChange(identifier, value) {
        setEnteredValues((prevValues) => ({
          ...prevValues,
          [identifier]: value,
        }));
        setDidEdit((prevEdit) => ({
          ...prevEdit,
          [identifier]: false,
        }));
      }
    
      function handleInputBlur(identifier) {
        setDidEdit((prevEdit) => ({
          ...prevEdit,
          [identifier]: true,
        }));
      }
    
    
    
    
    
    
    
    // *****
    
    
    const [formData, setFormData] = useState({
        name: '',
        price: '',
        code: '',
      
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'שדה חובה';
        if (!formData.price) newErrors.price = 'שדה חובה';
        if (!formData.code) newErrors.code = 'שדה חובה';
        // if (!formData.shippingAddress) {
        //     newErrors.shippingAddress = 'שדה חובה';

        // if (!formData.postcode) newErrors.postcode = 'שדה חובה';
        // if (!formData.confirmEmail) {
        //     newErrors.confirmEmail = 'שדה חובה';
        // } else if (formData.primaryEmailReceipts !== formData.primaryEmailReceipts) {
        //     newErrors.primaryEmailReceipts = 'אמייל לא תקין';
        // }
        return newErrors;
    };


    const handleSubmit = () => {
        // e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            // Submit the form data
            console.log(formData)
            addNewPaying()
            // setFormData({
            //     name: '',
            //     id: '',
            //     email: '',
            //     postcode: '',
            //     confirmEmail: ''
            // });
            // setErrors({});
        }
    };



    useEffect(()=>{
        if(row)
        {
        setFormData   ( {name: row.name,
        price: row.price,
        code: row.code,
        
        })}
    },[])
    const handleEditClass = async (id) => {
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
        await axios.put(`${GET_PRODUCT_URL}/${id}`, JSON.stringify({ 
        "name": formData.name,
        "price": formData.price,
        "code": formData.code,
     }), {
            headers: { 'Content-Type': 'application/json' }
        }).then(response => {
            if (response.status == 200) {
                // setnamecalss("")
                getDepartment()
                handleColse()
                console.log("Edit")
            }
        })
    }}

    const addNewPaying = async () => {
        const response = await axios.post(GET_PRODUCT_URL, JSON.stringify({
            "name": formData.name,
            "price": formData.price,
            "code": formData.code,
            
        }), {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log(response)
        if (response?.status == 200) {
            // setnamecalss("")
            getDepartment()
            handleColse()
        }
        else {
            console.log("error add class")
        }
    }
    return (
        <Modall showModal={showModal}>
        <div className=' w-full divide-y-2  divide-slate-500'>
            <div className='w-full bg-[#0d406b] flex justify-end'>
                <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={() => {
                    handleColse()
                }} >x</button>
            </div>
      
            {/* <form className='w-full p-5 flex flex-col  text-center text-sm'> */}
            <form className='max-md:w-[600px] m-[30px auto]  p-2 flex flex-col  ' style={{ direction: "rtl" }} >
           {/* ************************* */}

           






      {/* <div className="control-row">
        <Input
          label="Email"
          id="email"
          type="email"
          name="email"
          onBlur={() => handleInputBlur('email')}
          onChange={(event) => handleInputChange('email', event.target.value)}
          value={enteredValues.email}
          error={emailIsInvalid && 'Please enter a valid email!'}
        />

        <Input
          label="Password"
          id="password"
          type="password"
          name="password"
          onChange={(event) =>
            handleInputChange('password', event.target.value)
          }
          onBlur={() => handleInputBlur('password')}
          value={enteredValues.password}
          error={passwordIsInvalid && 'Please enter a valid password!'}
        />
      </div> */}
           
           
           
           
           
           
           
           
           
           {/* ************************* */}

          
               
                    <Input
                    className='grid col-span-5  border'
                    label="שם"      
                    type="text"
                    req={true}
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        error={errors.name}
                    />
                   

                    <Input
                        label="קוד המוצר"
                         className='grid col-span-5  border'
                        type="text"
                        id="code"
                        name="code"
                        req={true}
                        value={formData.code}
                        onChange={handleChange}
                        error={errors.code}
                    />
                 
                    
                    <Input
                     className='grid col-span-5  border'
                        label="מחיר"
                        type="text"
                        id="price"
                        name="price"
                        value={formData.price}
                        onChange={handleChange}
                        error={errors.price}
                        req={true}
                    />

                
            </form>
            <div className='w-full p-2   flex justify-end'>
                <button className='border bg-green-500 hover:bg-green-300 p-1 px-3' onClick={() => {
                     {
                        row ? handleEditClass(row.id) : handleSubmit()
                    }
                    // handleSubmit()


                }}>שמור</button>
                <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3' onClick={() => {
                    // reset()
                    // setShowModal(false)
                    handleColse()
                }}>בטל</button>
            </div>
        </div>

    </Modall>
  )
}

export default ModalNewProducts