import { createContext, useContext, useEffect, useState } from "react"
import Cookies from "universal-cookie"
import axios from "axios"
import { Navigate, redirect } from "react-router-dom"

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
    // const cookies = new Cookies();
    // const load = cookies.get('isAllow'); 
    // const [auth , setAuth]  = useState(load ? cookies.get('isAllow') : null)

    // useEffect(() => { 
    //     setAuth(cookies.get('isAllow')?cookies.get('isAllow'):null)
    //   }, [cookies]);
    
    const cookies = new Cookies();
    const [auth, setAuth] = useState( cookies.get('isAllow')?cookies.get('isAllow') : null)
    //   const [auth, setAuth] = useState(null);

    // בודק את המצב של המשתמש ברגע שהקומפוננטה נטענת
    useEffect(() => {
        const load = cookies.get('isAllow');
        setAuth(load ? load : null);  // אם יש ערך ב-cookie 'isAllow', עדכן את הסטייט
    }, [cookies]);  // לא צריך לעקוב אחרי השינויים של 'isAllow' ישירות, אנחנו בודקים פעם אחת

    //   const [auth, setAuth] = useState(localStorage.getItem('access_token') || null);

    //   useEffect(() => {
    //         const token = localStorage.getItem('access_token');
    //         if (token) {
    //             setAuth(token);
    //         } else {
    //             setAuth(null);
    //         }
    //     }, []);


    const login = (loginAuth) => {
        // cookies.set('bezkoder-jwt-refresh' , JSON.stringify(loginAuth['refersh_token']) ,  { path: '/'  , maxAge: 31536000})
        // axios.defaults.headers.common['Authorization'] = `Bearer ${loginAuth['access_token']}`;

    }
    const logout = async () => {

        // setSite_id(null)
        // cookies.remove('save_site')

        // const refershToken = cookies.get('bezkoder-jwt-refresh')
        // console.log(refershToken)
        // const {data} = await axios.post('signout' , {"token":refershToken});
        // cookies.remove('bezkoder-jwt-refresh')
        // axios.defaults.headers.common['Authorization'] = null;
        // console.log(data?.message)
        // cookies.remove('wonderPush_token')
        // return redirect ("/login") 
    }
    // const checkAuth=()=>{
    //     const cookies = new Cookies();
    //     const load = cookies.get('bezkoder-jwt-refresh'); 
    //     if(!load)
    //         {
    //             //  <Navigate to="/login" />
    //             redirect("/login")  
    //         }
    // }

    return (
        <AuthContext.Provider value={{
            auth, setAuth, login, logout,
            // checkAuth
        }}>
            {children}
        </AuthContext.Provider>
    )
}
export default AuthContext;


export const useAuth = () => useContext(AuthContext);
