import React, { useEffect, useState } from 'react'
import TrainingDaysFilter from './TrainingDaysFilter'
import DataTable from 'react-data-table-component'
import { BsPencil } from "react-icons/bs";
import { IoTrashOutline } from "react-icons/io5";
import { MdAssignment,MdBorderColor,MdContentCopy } from "react-icons/md"


import axios from 'axios'
import ModalNewTrainingDays from './ModalNewTrainingDays';
import ModalEdit from '../../ModalEdit';
import MoreOptions from './MoreOptions';
import { convertToIsraelTime } from '../../../util/formatsForDate';
import { HeaderTable } from '../../HeaderTable';


const URL_EXCLE='admin/qualification/get_filter_qualification_day_excel'
const GET_LIST_Filter = 'admin/qualification/before_filter_Qualification_day'
const GET_DELETE = 'admin/qualification/delete_Qualification_day'
const GET_URL = 'admin/qualification/get_filter_qualification_day'
const GET_URL_duplicated = 'admin/qualification/duplicated_qualification_details'
const TrainingDays = ({setHide}) => {
    const [modalIsOpen, setModalIsOpen] = useState("");
    const [id, setId] = useState("");
    const [fetchData, setFetchData] = useState([])
    const [loading, setloading] = useState(true)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1)

    const [dataFillter, setDataFillter] = useState({
        "statusQId": "",
        "instructorId": "",
        "place": "",
        "name": "",
        "dateSt": "",
        "dateEn": "",
        "number": "",
        "clusterId": "",
    })
    const [datafiler, setDatafiler] = useState("");
    
    const getDFilter = async () => {

        await axios.get(GET_LIST_Filter, {
        })
            .then(response => {
                setDatafiler(response?.data)

            })

    }
        useEffect(() => {
            getDFilter()
    }, [])

  
  
    const downloadExcel = async () => {
        try {
          const response = await axios.get(URL_EXCLE, {
            params: {
                ...dataFillter
              // member_number:"0001"
            },
            responseType: 'blob', // הגדרת התגובה כ-blob לצורך הורדת הקובץ
          });
      
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'report.xlsx');
          document.body.appendChild(link);
          link.click();
          link.remove();
        } catch (error) {
          console.error('Error downloading file:', error);
        }
      
        }
  
  
  
  
  
  
  
  
  
    const getTrainingDays = async (page) => {

    await axios.get(GET_URL,{
        params:{...dataFillter,
            "page":page
            ,"size":perPage,
            "sortName":"name",
            
            
        }})
        .then(response => {
            setFetchData(response?.data?.resQualificationsShows)
        setTotalRows(response?.data?.totalElements);
    })
    setloading(false)
    
}

const duplicated_qualification_details=async (id) => {

   const response= await axios.get(`${GET_URL_duplicated}/${id}`,{})
        if (response?.status=="200")
        {
            getTrainingDays()}
        else
        {
            console.log("error")
        }
    }

const handleDetele = async () => {

    await axios.delete(`${GET_DELETE}/${id.id}`).then(response => {
        if (response.status == 200) {
            handleColse()
            getTrainingDays()
            getDFilter()
            console.log("ok")
        }
        else {
            console.log("error")
        }
    })
}

useEffect(()=>{
    getTrainingDays(currentPage-1)
},[currentPage,perPage,dataFillter])

const handlePerRowsChange = async (newPerPage) => {
    setPerPage(newPerPage);

}
useEffect(() => {
    if (modalIsOpen) {
        document.body.style.overflow = 'hidden'; // Disable body scrolling
      } else {
        document.body.style.overflow = 'auto'; // Enable body scrolling
      }
  
      return () => {
        document.body.style.overflow = 'auto'; // Cleanup: ensure body scroll is reset
      };
    // setHide(modalIsOpen)
}, [modalIsOpen])
// const handleChange = (e) => {
//     const { name, value } = e.target;
//     setDataFillter({
//         ...dataFillter,
//         [name]: value
//     });
// }
const handlePageChange = (page) => {
    // fetchUsers(page);
    // getData(page);
    setCurrentPage(page);
  };
 
  const handleColse = () => {
    setModalIsOpen("")
}
  const columns = [
    {
        name: "פעולות",

        cell: row => <div className='flex'>
                  <button title="שכפול שורה" className=' border border-gray-500 ml-1 p-1 opacity-25 hover:opacity-100' onClick={() => {
                  duplicated_qualification_details(row?.id)
                }}><MdContentCopy /></button>

                
                  <button title="עוד אפשרויות" className=' border border-gray-500 ml-1 p-1 opacity-25 hover:opacity-100' onClick={() => {
                    setId(row)
                    setModalIsOpen("MoreOptions")
                }}><MdAssignment /></button>
            
          
            <button title="מחיקה" className=' border border-gray-500 ml-1 p-1 opacity-15 hover:opacity-100' onClick={() => {
                setId(row)
                setModalIsOpen("Delete")
            }}><IoTrashOutline /></button> 
            
            <button className=' border border-gray-500 ml-1 p-1 opacity-15 hover:opacity-100' onClick={() => {
            // setnamecalss(row?.description)
            // setAction("edit")
            setId(row)
            setModalIsOpen("Edit")
        }
        }><BsPencil /></button>
            </div>,
        style: {
            fontSize: '10px',
            justifyContent: 'center',

        },
        grow: 3,


    },
  {
    name: "כותרת התעודה",
    grow: 3,
    cell: (row) => row.titleCertificateDesc,
    style: {
        fontSize: '10px',
        justifyContent: 'center',
        width: '100px'
    },
    // sortable: true,
},
{
  name: "שם המדריך",
  grow: 4,
  cell: (row) => row.instructorName,
  style: {
      fontSize: '10px',
      justifyContent: 'center',
      width: '100px'
  },
//   sortable: true,
},
    {
      name: "סטטוס",
      grow: 3,
      cell: (row) => row.statusQualificationDesc,
      style: {
          fontSize: '10px',
          justifyContent: 'center',
          width: '100px'
      },
    //   sortable: true,
  },
  {
    name: "מיקום",
    grow: 3,
    cell: (row) => row.place,
    style: {
        fontSize: '10px',
        justifyContent: 'center',
        width: '100px'
    },
    // sortable: true,
},
    {
      name: "מספר משתתפים רשומים",
      grow: 3,
      cell: (row) => row.registerParticipants,
      style: {
          fontSize: '10px',
          justifyContent: 'center',
        //   width: '100px'
      },
    //   sortable: true,
  },
    {
      name: "מספר משתתפים מקסימלי",
      grow: 3,
      cell: (row) => row.maximumParticipants,
      style: {
          fontSize: '10px',
          justifyContent: 'center',
        //   width: '100px'
      },
    //   sortable: true,
  },
    {
        name: "תאריך התחלה",
        grow: 3,
        cell: (row) => row?.startDate?convertToIsraelTime(row?.startDate):"",
        style: {
            fontSize: '10px',
            justifyContent: 'center',
            // width: '100px'
        },
        // sortable: true,
    },


    {
        name: "שם יום הכשירות",
        grow: 3,
        cell: (row) => row.qualificationName,
        style: {
            fontSize: '10px',
            justifyContent: 'center',
            // width: '100px'
        },
        // sortable: true,


    },
    {
        name: "מספר יום הכשירות",
        grow: 3,
        cell: (row) => row?.qualificationNumber,
        style: {
            fontSize: '10px',
            justifyContent: 'center',
        },
        // sortable: true,
    },

    {
        name: "שם האשכול",
        cell: row => row?.clusterName,

        style: {
            fontSize: '10px',
            justifyContent: 'center',

        },
        grow: 3,


    },

    {
        name: "מספר אשכול",
        cell: row => row?.clusterNumber,

        style: {
            fontSize: '10px',
            justifyContent: 'center',

        },
        grow: 3,


    },



];
const conditionalRowStyles = [
    {
      when: row => row?.statusQualificationDesc =="פעיל", // תנאי - צביעת שורה עם score מעל 50
      style: {
        backgroundColor: '#d9edf7', // צבע רקע לשורה
                  // צבע הטקסט
        '&:hover': {
          cursor: 'pointer',       // אפקט hover
        },
      },
     
    },{
    when: row => row?.statusQualificationDesc =="ברישום", // תנאי - צביעת שורה עם score מעל 50
    style: {
      backgroundColor: '#fcf8e3', // צבע רקע לשורה
      color: 'black',             // צבע הטקסט
      '&:hover': {
        cursor: 'pointer',       // אפקט hover
      },
    }},
    {when: row => row?.statusQualificationDesc =="הסתיים", // תנאי - צביעת שורה עם score מעל 50
    style: {
      backgroundColor: '#dff0d8', // צבע רקע לשורה
      color: 'black',             // צבע הטקסט
      '&:hover': {
        cursor: 'pointer',       // אפקט hover
      },
    }},
    {when: row => row?.statusQualificationDesc =="בוטל", // תנאי - צביעת שורה עם score מעל 50
    style: {
      backgroundColor: '#ffd3d3', // צבע רקע לשורה
      color: '#333',             // צבע הטקסט
      '&:hover': {
        cursor: 'pointer',       // אפקט hover
      },
    }}
    
  ];


const customStyles = {
    table: {
        style: {
          border: "1px solid black", // גבולות לטבלה כולה
        },
      },
    header: {
        style: {

            justifyContent: 'center',

        },
    },
    id: {
        style: {
            '&:2': { backgroundColor: '#EEEEEE' },
        }
    },
    headRow: {
        style: {
            justifyContent: 'center',
                //  borderTopWidth: '1px',
           
            // borderTopColor: defaultThemes.default.divider.default,
        },
    },
    headCells: {
        style: {
            '&:not(:last-of-type)':{borderRight: "1px solid black"}, // גבולות בין תאים
            // whiteSpace: 'normal',
                // overflow: 'visible',
                // textOverflow: 'clip',
                // maxWidth: 'none', // Allow headers to expand as needed
                // padding: '10px'
                // ,
                // borderBottom: "1px solid black", // גבולות לכותרת הטבלה

            justifyContent: 'center',
            
            // borderRightColor: defaultThemes.default.divider.default,

        },
    },
    cells: {
        style: {
           '&:not(:last-of-type)': { borderRight: "1px solid black", }// גבולות בין תאים

            // 
             

                
                // borderRightStyle: 'solid',
                // borderRightWidth: '1px',
                // borderRightColor: "black",
                // borderTopStyle: "solid",
                // borderTopWidth: "1px",
                // borderTopColor: "black",
                
                // borderRightColor: defaultThemes.default.divider.default,
            } 
        // },
    },
    rows: {
        style: {
            // borderStyle: 'solid',
            //     borderWidth: '1px',
            //     borderColor: "black",
            borderTop: "1px solid black", // גבולות בין שורות

            backgroundColor: '#FFFFFF',
            '&:active': {
                backgroundColor: '#EEEEEE',
            },
        },
    },
}
  return (
    <div className='w-full p-3'>
      <TrainingDaysFilter
      datafiler={datafiler} 
      setDataFillterout={setDataFillter}
      ></TrainingDaysFilter>
      <HeaderTable active={"ימי כשירות"} setModalIsOpen={setModalIsOpen} downloadExcel={downloadExcel} howmach={fetchData?.length}/>
      <DataTable columns={columns} data={fetchData} pagination customStyles={customStyles} 
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            conditionalRowStyles={conditionalRowStyles} // העברת הסגנון המותנה
            progressPending={loading} className='border' >
            </DataTable>
            {modalIsOpen == "New" && <ModalNewTrainingDays showModal={modalIsOpen == "New"} handleColse={handleColse} getDepartment={getTrainingDays} getDFilter={getDFilter}>
            </ModalNewTrainingDays>}
            {modalIsOpen == "Edit" && <ModalNewTrainingDays showModal={modalIsOpen == "Edit"} handleColse={handleColse} getDepartment={getTrainingDays} getDFilter={getDFilter} row={id}>
            </ModalNewTrainingDays>}
            {modalIsOpen == "Delete" && <ModalEdit showModal={modalIsOpen == "Delete"} handleColse={handleColse} handleDetele={handleDetele}></ModalEdit>}
            {modalIsOpen == "MoreOptions" && <MoreOptions handleColse={handleColse} row={id}></MoreOptions>}
          
      </div>
  )
}

export default TrainingDays