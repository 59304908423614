import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Modall from '../Modall'

const GET_PAYING_CUSTOMERS_URL = 'admin/setting/paying_customer'


const ModalNewPaying = ({ showModal, children, handleColse, getFilter, getDepartment, row = "" }) => {
    
    const [formData, setFormData] = useState(row ? {
        name: row.name,
        id: row.identity,
        shippingAddress: row.shippingAddress,
        postcode: row.postalCode,
        primaryEmailReceipts: row.primaryEmailReceipts,
    }
        :
        {
            name: '',
            id: '',
            shippingAddress: '',
            postcode: '',
            primaryEmailReceipts: ''
        });

    const [errors, setErrors] = useState({});

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({
    //         ...formData,
    //         [name]: value
    //     });
    // };

    // const validate = () => {
    //     const newErrors = {};
    //     if (!formData.name) newErrors.name = 'שדה חובה';
    //     if (!formData.id) newErrors.id = 'שדה חובה';
    //     // if (!formData.shippingAddress) {
    //     //     newErrors.shippingAddress = 'שדה חובה';

    //     // if (!formData.postcode) newErrors.postcode = 'שדה חובה';
    //     // if (!formData.confirmEmail) {
    //     //     newErrors.confirmEmail = 'שדה חובה';
    //     // } else if (formData.primaryEmailReceipts !== formData.primaryEmailReceipts) {
    //     //     newErrors.primaryEmailReceipts = 'אמייל לא תקין';
    //     // }
    //     return newErrors;
    // };






    const validateField = (name, value) => {
        let error = "";

        switch (name) {
            case "name":
                if (!value.trim()) {
                    error = "שם לקוח הינו שדה חובה.";
                }
                break;
            case "id":
                if (!value.trim()) {
                    error = "הינו שדה חובה."
                }
                if (value.trim() && !/^\d+$/.test(value)) {
                    error = "תעודת זהות חייבת להיות מספרים בלבד.";
                }
                break;
            case "shippingAddress":
                if (!value.trim()) {
                    error = " הינו שדה חובה.";

                }
                // else{  
                // if(value.trim() && !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(value)) {
                //   error = "כתובת דוא\"ל לא תקינה.";
                // }}
                break;
            case "postcode":
                if (value && !/^\d{5,7}$/.test(value)) {
                    error = "מיקוד צריך להיות מספר בן 5-7 ספרות.";
                }
                break;
            case "primaryEmailReceipts":

                if (value.trim() && !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(value)) {
                    error = "כתובת דוא\"ל לא תקינה.";
                }
                break;

            default:
                break;
        }

        return error;
    };

    // פונקציה לעדכון הערכים ובדיקת התקינות
    const handleChange = (e) => {
        const { name, value } = e.target;

        // עדכון הערך בטופס
        setFormData({ ...formData, [name]: value });

        // בדיקת שדה ספציפי בזמן הקלדה
        const fieldError = validateField(name, value);
        setErrors({ ...errors, [name]: fieldError });
    };



    // בדיקת תקינות של הטופס
    const validate = () => {
        const newErrors = {};

        if (!formData?.name?.trim()) {
            newErrors.name = "שם לקוח הינו שדה חובה.";
        }
        if (!formData?.id?.trim()) {
            newErrors.id = "הינו שדה חובה."
        }
        if (formData?.id?.trim() && !/^\d+$/.test(formData.id)) {
            newErrors.id = "תעודת זהות חייבת להיות מספרים בלבד.";
        }
        if (!formData?.shippingAddress?.trim()) {
            newErrors.shippingAddress = "הינו שדה חובה.";
        }
        // if (formData?.shippingAddress?.trim()&& !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(formData?.shippingAddress)) {
        //     newErrors.shippingAddress = "כתובת דוא\"ל לא תקינה.";
        // }

        if (formData?.postcode && !/^\d{5,7}$/.test(formData.postcode)) {
            newErrors.postcode = "מיקוד צריך להיות מספר בן 5-7 ספרות.";
        }

        if (formData?.primaryEmailReceipts?.trim() && !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(formData?.primaryEmailReceipts)) {
            newErrors.primaryEmailReceipts = "כתובת דוא\"ל לא תקינה.";
            console.log(formData?.primaryEmailReceipts)
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // שליחת טופס
    const handleSubmit = (e) => {
        // e.preventDefault();

        if (validate()) {
            addNewPaying()
            // console.log("טופס נשלח בהצלחה:", formData);

        }
    };











    // const handleSubmit = () => {
    //     // e.preventDefault();
    //     const validationErrors = validate();
    //     if (Object.keys(validationErrors).length > 0) {
    //         setErrors(validationErrors);
    //     } else {
    //         // Submit the form data
    //         console.log(formData)
    //         addNewPaying()
    //         // setFormData({
    //         //     name: '',
    //         //     id: '',
    //         //     email: '',
    //         //     postcode: '',
    //         //     confirmEmail: ''
    //         // });
    //         // setErrors({});
    //     }
    // };








    const handleEditClass = async (id) => {
        // const validationErrors = validate();
        if (validate()) {
            await axios.put(`${GET_PAYING_CUSTOMERS_URL}/${id}`, JSON.stringify({
                "name": formData.name,
                "identity": formData.id,
                "shippingAddress": formData.shippingAddress,
                "postalCode": formData.postcode,
                "primaryEmailReceipts": formData.primaryEmailReceipts,
                "attendanceBox": 0
            }), {
                headers: { 'Content-Type': 'application/json' }
            }).then(response => {
                if (response.status == 200) {
                    // setnamecalss("")
                    getDepartment()
                    getFilter()
                    handleColse()
                    console.log("Edit")
                    alert("עודכן");
                }
            })
        }
    }

    const addNewPaying = async () => {
        const response = await axios.post(GET_PAYING_CUSTOMERS_URL, JSON.stringify({
            "name": formData.name,
            "identity": formData.id,
            "shippingAddress": formData.shippingAddress,
            "postalCode": formData.postcode,
            "primaryEmailReceipts": formData.primaryEmailReceipts,
            "attendanceBox": 0
        }), {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log(response)
        if (response?.status == 200) {
            // setnamecalss("")
            alert("הטופס נשלח בהצלחה!");
            await getDepartment()
            await getFilter()
            handleColse()
        }
        else {
            console.log("error add class")
        }
    }

    return (
        <Modall showModal={showModal}>
            <div className=' w-full divide-y-2  divide-slate-500'>
                <div className='w-full bg-[#0d406b] flex justify-end'>
                    <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={() => {
                        handleColse()
                    }} >x</button>
                </div>


                <form className='w-full p-5 flex flex-col  text-center text-sm'>




                    <div className='w-full pt-2 flex justify-end'>

                        <input
                            className=' w-1/2 border  text-right'
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        // onFocus={handleFoucs}
                        />
                        <label htmlFor="name " className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>שם הלקוח</p></label>

                    </div>
                    {errors.name && <span className="text-red-600">{errors.name}</span>}
                    <div className='w-full pt-2 flex justify-end'>

                        <input
                            className='border w-1/2 text-right'
                            type="text"
                            id="id"
                            name="id"
                            value={formData.id}
                            onChange={handleChange}
                        //  ?               onFocus={handleFoucs}
                        />
                        <label htmlFor="id" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>.ח.פ./ע.מ./ת.ז</p></label>

                    </div>
                    {errors.id && <span className="text-red-600">{errors.id}</span>}

                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right'
                            type="text"
                            id="shippingAddress"
                            name="shippingAddress"
                            value={formData.shippingAddress}
                            onChange={handleChange}
                        // onKeyPress={handleKeyPress}
                        // onFocus={handleFoucs}
                        />
                        <label htmlFor="shippingAddress" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>כתובת למשלוח דואר</p></label>

                    </div>
                    {errors.shippingAddress && <span className="text-red-600">{errors.shippingAddress}</span>}

                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right'
                            type="number"
                            id="postcode"
                            name="postcode"
                            value={formData.postcode}
                            onChange={handleChange}
                        />
                        <label htmlFor="postcode" className=' w-[30%] '>מיקוד</label>
                    </div>
                    {errors.postcode && <span className="error">{errors.postcode}</span>}
                    <div className="w-full pt-2 flex justify-end items-center">
                        <input
                            className='border w-1/2 h-fit text-right '
                            type="email"
                            id="primaryEmailReceipts"
                            name="primaryEmailReceipts"
                            value={formData.primaryEmailReceipts}
                            onChange={handleChange}

                        />
                        <label htmlFor="primaryEmailReceipts" className=' w-[30%]'>מייל ראשי למשלוח קבלות</label>

                    </div>
                    {errors.primaryEmailReceipts && <span className="error">{errors.primaryEmailReceipts}</span>}


                </form>
                <div className='w-full p-2   flex justify-end'>
                    <button className='border bg-green-500 hover:bg-green-300 p-1 px-3' onClick={() => {
                        {
                            row ? handleEditClass(row.id) : handleSubmit()
                        }
                        // handleSubmit()


                    }}>שמור</button>
                    <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3' onClick={() => {
                        // reset()
                        // setShowModal(false)
                        handleColse()
                    }}>בטל</button>
                </div>
            </div>

        </Modall>
    )
}

export default ModalNewPaying