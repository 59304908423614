import React,{useEffect,useState} from 'react'
import SubTab from '../SubTab'
import Status_member from './Forms_sub/Status_member'
import Status_qualification from './Forms_sub/Status_qualification'
import Cluster from './Forms_sub/Cluster'

const Forms = () => {

const [active,setActive]=useState("חברי לשכה")
const [subactive,setSubActive]=useState("סטטוסים לימי כשירות")



  return (
    <div className='w-full p-3'>
        <ul className='flex flex-row-reverse  m-0 list-none  '   >
            <SubTab active={active=="חברי לשכה"} onClick={()=>{setActive("חברי לשכה") }}>חברי לשכה </SubTab>
            <SubTab active={active=="ימי כשירות"} onClick={()=>{setActive("ימי כשירות")}}>ימי כשירות</SubTab>
        </ul>
        {active=="ימי כשירות"&&
        <ul className='flex flex-row-reverse my-2 m-0 mr-2 list-none  '   >
        <SubTab active={subactive=="סטטוסים לימי כשירות"} onClick={()=>{setSubActive("סטטוסים לימי כשירות")}}>סטטוסים לימי  כשירות</SubTab>
        <SubTab active={subactive=="אשכולות ימי כשירות"} onClick={()=>{setSubActive("אשכולות ימי כשירות")}} >אשכולות ימי כשירות</SubTab>
    </ul>}
        {active=="חברי לשכה"&& <Status_member/>}
        {active=="ימי כשירות"&&subactive=="סטטוסים לימי כשירות"&& <Status_qualification/>}
        {active=="ימי כשירות"&&subactive=="אשכולות ימי כשירות"&& <Cluster/>}
      </div>
  )
}

export default Forms