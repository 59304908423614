import React, { useState } from 'react'
import Input from '../../ui/Input'
import axios from 'axios';

const NewCluster = ({handleColse, getData, row = ""}) => {
    const GET_CLUSTR_URL="admin/setting/cluster"
    const [formData, setFormData] = useState(row ? {
        name: row?.name,
        number: row?.number,
        }
        :
        {
            name: '',
            number: '',
        });
        const [errors, setErrors] = useState({});


        const validateField = (name, value) => {
            let error = "";
    
            switch (name) {
                case "name":
                    if (!value.trim()) {
                        error = "   שדה חובה.";
                    }
                    break;
                case "number":
                    if (!value.trim()) {
                        error = " שדה חובה."
                    }
                    if (value.trim() && !/^\d+$/.test(value)) {
                        error = "תעודת זהות חייבת להיות מספרים בלבד.";
                    }
                    break;
                // case "shippingAddress":
                //     if (!value.trim()) {
                //         error = " הינו שדה חובה.";
    
                //     }
                    // else{  
                    // if(value.trim() && !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(value)) {
                    //   error = "כתובת דוא\"ל לא תקינה.";
                    // }}
                    break;
                // case "postcode":
                //     if (value && !/^\d{5,7}$/.test(value)) {
                //         error = "מיקוד צריך להיות מספר בן 5-7 ספרות.";
                //     }
                    // break;
                
                default:
                    break;
            }
    
            return error;
        };
    
        // פונקציה לעדכון הערכים ובדיקת התקינות
        const handleChange = (e) => {
            const { name, value } = e.target;
    
            // עדכון הערך בטופס
            setFormData({ ...formData, [name]: value });
    
            // בדיקת שדה ספציפי בזמן הקלדה
            const fieldError = validateField(name, value);
            setErrors({ ...errors, [name]: fieldError });
        };
    
        const handleSubmit = (e) => {
            // e.preventDefault();
    
            if (validate()) {
                addNew()
                // console.log("טופס נשלח בהצלחה:", formData);
    
            }
        };
        const addNew = async () => {
            const response = await axios.post(GET_CLUSTR_URL, 
            formData, {
                headers: { 'Content-Type': 'application/json' }
            })
            console.log(response)
            if (response?.status == 200) {
                // setnamecalss("")
                alert("הטופס נשלח בהצלחה!");
                await getData()
                await handleColse()
            }
            else {
                console.log("error add class")
            }
        }
    
        // בדיקת תקינות של הטופס
        const validate = () => {
            const newErrors = {};
    
            if (!formData?.name?.trim()) {
                newErrors.name = "שם לקוח הינו שדה חובה.";
            }
            if (!formData?.number?.trim()) {
                newErrors.number = "הינו שדה חובה."
            }
            if (formData?.number?.trim() && !/^\d+$/.test(formData.number)) {
                newErrors.number = "תעודת זהות חייבת להיות מספרים בלבד.";
            }
            // if (formData?.postcode && !/^\d{5,7}$/.test(formData.postcode)) {
            //     newErrors.postcode = "מיקוד צריך להיות מספר בן 5-7 ספרות.";
            // }
    
    
            setErrors(newErrors);
            return Object.keys(newErrors).length === 0;
        };
        const handleEdit = async (id) => {
            // const validationErrors = validate();
            if (validate()) {
                await axios.put(`${GET_CLUSTR_URL}/${id}`, formData, {
                    headers: { 'Content-Type': 'application/json' }
                }).then(response => {
                    if (response.status == 200) {
                        getData()
                        handleColse()
                        alert("עודכן");
                    }
                })
            }
        }





  return (
    <div className='absolute top-0 h-screen overflow-y-auto left-0 flex flex-col items-center pt-5 w-full drop-shadow-2xl shadow-2xl bg-[#FDFFFC]/50 '
        // onClick={() => {
        //     // handleColse()
        // }}
        >
            <div className='bg-white w-1/2 rounded-lg divide-y-2  divide-slate-500 border-0 border-emerald-50 '>
                <div className='w-full bg-[#0d406b] rounded-t-md flex justify-end border-[#0d406b] border-2'>
                    <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={() => {
                        handleColse()
                    }} >x</button>
                </div>
               
                <form className='w-full p-4 flex flex-col  text-center text-sm ' dir='rtl'>
                    
                    <Input
                        label='מספר אשכול'
                        className='grid col-span-5  border'
                        type="number"
                        id="number"
                        name="number"
                        error={errors?.number}
                        value={formData?.number}
                        onChange={handleChange}
                    />
                    <Input
                        label='שם האשכול'
                        req={true}
                        className='grid col-span-5  border'
                        type="text"
                        id="name"
                        name="name"
                        error={errors?.name}
                        value={formData?.name}
                        onChange={handleChange}
                    />


           

                </form>
                <div className='w-full p-2   flex justify-end'>
                    <button className='border bg-green-500 hover:bg-green-300 p-1 px-3' onClick={() => {
                        {
                            // console.log(formData)
                            row ? handleEdit(row.id) : handleSubmit()
                        }


                    }}>שמור</button>
                    <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3' onClick={() => {
                        // reset()
                        // setShowModal(false)
                        handleColse()
                    }}>בטל</button>
                </div>
            </div>
            </div>)
}
 

export default NewCluster