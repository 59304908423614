import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ReDataTable from '../../../ui/ReDataTable';
import { HeaderTable } from '../../../HeaderTable';
import { convertToIsraelTime, formatOnlyTime } from '../../../../util/formatsForDate';
const SignatureWindows = ({ row }) => {
  const [fetchData, setFetchData] = useState([])
  const [loading, setloading] = useState(true)
  const [totalRows, setTotalRows] = useState(0);
  
  
  const GET_URL = `admin/qualification/signature_windows_by_qualification/${row?.id}`
  const getData = async (page, perPage) => {
    const response = await axios.get(GET_URL, {
      params: {
        "page": page
        , "size": perPage,
      }
    })
    if (response.status == "200") {
      const response1 = (response?.data)
      const response2 = (response?.data?.totalElements);
      return [response1, response2];
    }
    const response1 = []
    const response2 = []
    return [response1, response2];
  };



  const getDataTable = async (page,perPage) => {


        
    const [result1, result2] = await getData(page,perPage)
    setFetchData(result1);
    setTotalRows(result2); 
    setloading(false)
    console.log(fetchData)
  };


  const columns = [



    {
      name: "שעת סיום",
      grow: 2,
      cell: (row) => row?.timeEnd?formatOnlyTime(row?.timeEnd):"",
      style: {
        fontSize: '16px',
        justifyContent: 'center',
        // width: '100px'
      },
      // sortable: true,
    },
    {
      name: "שעת התחלה",
      grow: 2,
      cell: (row) => row?.timeStart?formatOnlyTime(row?.timeStart):"",
      style: {
        fontSize: '16px',
        justifyContent: 'center',
        // width: '100px'
      },
      // sortable: true,
    },
    {
      name: "תאריך",
      grow: 2,
      cell: (row) => row?.timeDay?convertToIsraelTime(row?.timeDay):"",
      style: {
        fontSize: '16px',
        justifyContent: 'center',
        width: '100px'
      },
      // sortable: true,
    }




  ];

  return (
    <div style={{ direction: "ltr" }}>
      <HeaderTable active={"ריכוז חלונות חתימה"} />
      <ReDataTable  columns={columns} getDataTable={getDataTable} fetchData={fetchData} loading={loading} totalRows={totalRows} />
    </div>
  )
}

export default SignatureWindows