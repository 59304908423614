import React, { useState } from 'react'
import SubTab from '../../SubTab'
import Summary_purchased_used_products_payCustomer from './Summary_purchased_used_products_payCustomer'
import Used_day_via_member_spend from './Used_day_via_member_spend'
import Paying_customer_members from './Paying_customer_members'
import Contacts from './Contacts'
import Purchase_archive from './Purchase_archive'
import MoreOptionsCard from '../../ui/MoreOptionsCard'

const Paying_customers_More_options = ({ handleColse ,row}) => {
    const [active, setActive] = useState("אנשי קשר")
    return (
        
        // <div className='absolute top-0 left-0 flex flex-col items-center pt-2 w-full min-h-screen drop-shadow-2xl shadow-2xl bg-slate-600/80 '>
        //     <div className='bg-white w-[90%] rounded-lg divide-y-2  divide-slate-500 border-0 border-emerald-50 '>
        //         <div className='w-full bg-[#0d406b] rounded-t-md flex justify-end border-[#0d406b] border-2'>
        //             <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={() => {
        //                 handleColse()
        //             }} >x</button>
        //         </div>
        <MoreOptionsCard handleColse={handleColse}>
                <div className='w-full  p-2 flex flex-col  ' style={{ direction: "rtl" }} >
                    <h3 className='font-bold underline font-mono text-lg text-center' >{row?.name}</h3>
                    <div className='bg-[#f3f3f3] p-4 ' >

                        <ul className='flex flex-row  m-0 list-none'>
                            <SubTab active={active == "אנשי קשר"} onClick={() => { setActive("אנשי קשר") }}>אנשי קשר </SubTab>
                            <SubTab active={active == "ממונים רשומים"} onClick={() => { setActive("ממונים רשומים") }}>ממונים רשומים</SubTab>
                            <SubTab active={active == "ארכיון רכישות"} onClick={() => { setActive("ארכיון רכישות") }}>ארכיון רכישות</SubTab>
                            <SubTab active={active == "סיכום מוצרים שנרכשו"} onClick={() => { setActive("סיכום מוצרים שנרכשו") }}>סיכום מוצרים שנרכשו</SubTab>
                            <SubTab active={active == "ימי עיון שנוצלו עי הלקוח"} onClick={() => { setActive("ימי עיון שנוצלו עי הלקוח") }}>ימי עיון שנוצלו ע"י הלקוח</SubTab>
                        </ul>
                        <div className='bg-white w-full '>
                            {active == "אנשי קשר" && <Contacts row={row} />}
                            {active == "ימי עיון שנוצלו עי הלקוח" && <Used_day_via_member_spend row={row} />}
                            {active == "סיכום מוצרים שנרכשו" && <Summary_purchased_used_products_payCustomer row={row} />}
                            {active == "ממונים רשומים" && <Paying_customer_members row={row} />}
                            {active == "ארכיון רכישות" && <Purchase_archive row={row} />}


                        </div>

                    </div>
                </div>
                </MoreOptionsCard>
        //         <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3 mb-3' onClick={() => {
        //             // reset()
        //             // setShowModal(false)
        //             handleColse()
        //         }}>סגור</button>
        //     </div>
        // </div>
    )
}

export default Paying_customers_More_options