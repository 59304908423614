import React,{useEffect,useState} from 'react'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import { HeaderTable } from '../../HeaderTable'
import SubTab from '../../SubTab'
import { BsPencil } from "react-icons/bs";
import { IoTrashOutline } from "react-icons/io5";
import ModalEdit from '../../ModalEdit'
import ModleNewStatus_Member from './ModleNewStatus_Member'
const GET_URLDELETE = 'admin/setting/status_member'

const Status_member = () => {
    
const [fetchData, setFetchData] = useState([])
    const [loading, setloading] = useState(true)
    // const [active,setActive]=useState("חברי לשכה")
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const GET_URL = 'admin/setting/status_member'
    const [modalIsOpen, setModalIsOpen] = useState("");
    const [id, setId] = useState("");
    const getForms = async (page) => {
        // setloading(true);
        // await axios.get(`${GET_PAYING_CUSTOMERS_URL}s`).then(response => {
            await axios.get(GET_URL,{
                // params:{"page":page
                //     ,"size":perPage,
                    
                // }
                })
        .then(response => {
            setFetchData(response?.data)
            setTotalRows(response?.data?.totalElements);
        })
        setloading(false)
        console.log(fetchData)}
    

    useEffect(()=>{
        getForms(currentPage-1)
    },[currentPage,perPage])

    const handlePerRowsChange = async (newPerPage) => {
        setPerPage(newPerPage);

    }

   

    const handlePageChange = (page) => {
        // fetchUsers(page);
        setFetchData(page);
        setCurrentPage(page);
      };


      const columns = [
       
        {
            name: "פעולות",
            
            selector: (row) => 
                <div className='w-full'>
                <button title="מחיקה" className=' border border-gray-500 ml-1 p-1 opacity-25 hover:opacity-100' onClick={() => {
                setId(row)
                setModalIsOpen("Delete")
            }}><IoTrashOutline /></button>
            <button className=' border border-gray-500 p-1  ml-1 opacity-25 hover:opacity-100' onClick={() => {
                // setnamecalss(row?.description)
                // setAction("edit")
                setId(row)
                setModalIsOpen("Edit")
            }
            }><BsPencil /></button></div>,
            style: {
                fontSize: '16px',
                justifyContent: 'center',
                width: '100px'
            },
            sortable: true,
        }
       ,
        {
            name: "תיאור סטטוס",
            grow: 4,
            selector: (row) => row.statusDescription,
            style: {
                fontSize: '16px',
                justifyContent: 'center',
                width: '100px'
            },
            sortable: true,
        }
        

    ]



    const customStyles = {
        header: {
            style: {

                justifyContent: 'center',

            },
        },
        id: {
            style: {
                '&:2': { backgroundColor: '#EEEEEE' },
            }
        },
        headRow: {
            style: {
                justifyContent: 'center',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                // borderTopColor: defaultThemes.default.divider.default,
            },
        },
        headCells: {
            style: {

                justifyContent: 'center',
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                // borderRightColor: defaultThemes.default.divider.default,

            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {

                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    // borderRightColor: defaultThemes.default.divider.default,
                },
            },
        },
        rows: {
            style: {
                backgroundColor: '#FFFFFF',
                '&:active': {
                    backgroundColor: '#EEEEEE',
                },
            },
        },
    }
    const handleDetele = async () => {

        await axios.delete(`${GET_URLDELETE}/${id.statusId}`).then(response => {
            if (response.status == 200) {
                handleColse()
                getForms()
                
                console.log("ok")
            }
            else {
                console.log("error")
            }
        })
    }
    const handleColse = () => {
        setModalIsOpen("")
    }
    useEffect(() => {
        if (modalIsOpen) {
            document.body.style.overflow = 'hidden'; // Disable body scrolling
          } else {
            document.body.style.overflow = 'auto'; // Enable body scrolling
          }
      
          return () => {
            document.body.style.overflow = 'auto'; // Cleanup: ensure body scroll is reset
          };
        // setHide(modalIsOpen)
    }, [modalIsOpen])
  return (
    <div className='w-full '>
    <ul className='flex flex-row-reverse my-2 m-0 mr-2 list-none  '   >
        <SubTab active={true} >סטטוס לחברי הלשכה</SubTab>
    </ul>
    {/* <HeaderTable  active={"סטטוסים לחברי הלשכה"} ></HeaderTable> */}
    <HeaderTable  active={"סטטוסים לחברי הלשכה"} setModalIsOpen={setModalIsOpen} />
        <DataTable columns={columns} data={fetchData} pagination customStyles={customStyles} 
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        progressPending={loading} className='border' >
        </DataTable>
        {modalIsOpen == "New" && <ModleNewStatus_Member showModal={modalIsOpen == "New"} handleColse={handleColse} getDepartment={getForms}>
            </ModleNewStatus_Member>}
            {modalIsOpen == "Edit" && <ModleNewStatus_Member showModal={modalIsOpen == "Edit"} handleColse={handleColse} getDepartment={getForms} row={id}>
            </ModleNewStatus_Member>}
        {modalIsOpen == "Delete" && <ModalEdit showModal={modalIsOpen == "Delete"} handleColse={handleColse} handleDetele={handleDetele}></ModalEdit>}

        </div>
  )
}

export default Status_member