import React, { useRef, useState } from "react";
import './SignatureModal.css';
const SignatureModal = ({ onClose, onSave,setdataSign,setFile })=> {
    const canvasRef = useRef(null);
    const isDrawing = useRef(false);
  
    const startDrawing = (e) => {
      e.preventDefault(); // מניעת פעולה ברירת מחדל
      isDrawing.current = true;
      const ctx = canvasRef.current.getContext("2d");
      const rect = canvasRef.current.getBoundingClientRect();
      const x = (e.clientX || e.touches[0].clientX) - rect.left;
      const y = (e.clientY || e.touches[0].clientY) - rect.top;
  
      ctx.beginPath();
      ctx.moveTo(x, y);
    };
  
    const draw = (e) => {
      e.preventDefault(); // מניעת פעולה ברירת מחדל
      if (!isDrawing.current) return;
  
      const ctx = canvasRef.current.getContext("2d");
      const rect = canvasRef.current.getBoundingClientRect();
      const x = (e.clientX || e.touches[0].clientX) - rect.left;
      const y = (e.clientY || e.touches[0].clientY) - rect.top;
  
      ctx.lineTo(x, y);
      ctx.strokeStyle = "black"; // צבע החתימה
      ctx.lineWidth = 2; // עובי הקו
      ctx.stroke();
    };
  
    const stopDrawing = () => {
      isDrawing.current = false;
    };
  
    const clearCanvas = () => {
      const ctx = canvasRef.current.getContext("2d");
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    };
  
    const handleSave = () => {
      const canvas = canvasRef.current;
      const signature = canvas.toDataURL("image/png"); // שמירת החתימה כתמונה
      onSave(signature);
      setFile(canvas)
      if (canvas) {


        if (!canvas) return;

        const dataURL = canvas.toDataURL('image/png');
        const byteString = atob(dataURL.split(',')[1]);
        const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
        const arrayBuffer = new Uint8Array(byteString.length);
      
        for (let i = 0; i < byteString.length; i++) {
          arrayBuffer[i] = byteString.charCodeAt(i);
        }
      
        const blob = new Blob([arrayBuffer], { type: mimeString });
        setFile(blob)
      }

      setdataSign(true)
      onClose()
    };
  
    return (
      <div className="modal-overlay">
        <div className="modal">
          <button className="close-btn" onClick={onClose}>
            ✕
          </button>
          <canvas
            ref={canvasRef}
            width={250}
            height={200}
            className="signature-canvas"
            onMouseDown={startDrawing}
            onMouseMove={draw}
            onMouseUp={stopDrawing}
            onMouseLeave={stopDrawing}
            onTouchStart={startDrawing}
            onTouchMove={draw}
            onTouchEnd={stopDrawing}
          ></canvas>
          <div className="button-group">
            
            <button className="save-btn" onClick={handleSave}>
              שמירה
            </button>
            <button className="clear-btn" onClick={clearCanvas}>
              ניקוי
            </button>
          </div>
        </div>
      </div>
    );
  }

export default SignatureModal